<template>
  <div class="nav-selectables-list" :class="{ mobile }">
    <div class="nav-selectable" @click="isOpenLanguageDropdown = !isOpenLanguageDropdown">
      <img :src="getActiveLanguage().icon" class="nav-selectable__icon" alt="flag" srcset="">
      <img src="@/assets/images/navigation/arrow.svg" alt="arrow" class="nav-selectable__arrow" :class="{'nav-selectable__arrow_active': isOpenLanguageDropdown}">

      <div class="nav-selectable__dropdown" v-if="isOpenLanguageDropdown">
        <div class="nav-selectable__dropdown__item" :class="{'active': getActiveLanguage().name === language.name}" v-for="language of languages" :key="language.name" @click="selectLanguage(language)">
          <img :src="language.icon" class="nav-selectable__dropdown__item__icon" alt="flag" srcset="">
          {{ language.name.toUpperCase() }}
        </div>
      </div>
    </div>

    <div class="nav-selectable nav-selectable_currency" @click="isOpenCurrencyDropdown = !isOpenCurrencyDropdown">
      {{ $store.getters.currencySign }}
      <img src="@/assets/images/navigation/arrow.svg" alt="arrow" class="nav-selectable__arrow" :class="{'nav-selectable__arrow_active': isOpenCurrencyDropdown}">

      <div class="nav-selectable__dropdown" v-if="isOpenCurrencyDropdown">
        <div class="nav-selectable__dropdown__item" @click="setCurrency('rub')">
          <span>₽</span>
          RUB
        </div>

        <div class="nav-selectable__dropdown__item" @click="setCurrency('usd')">
          <span>$</span>
          USD
        </div>

        <div class="nav-selectable__dropdown__item" @click="setCurrency('eur')">
          <span>€</span>
          EUR
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './selectables.scss'

import languages from '@/constants/languages'

export default {
  name: 'Selectables',
  props: ['mobile'],
  data () {
    return {
      isOpenLanguageDropdown: false,
      isOpenCurrencyDropdown: false,
      languages
    }
  },
  methods: {
    getActiveLanguage () {
      for (const language of languages) {
        if (this.$store.state.language === language.name) return language
      }

      return languages[0]
    },
    selectLanguage (language) {
      this.$i18n.locale = language.name
      this.$store.commit('setLanguage', language.name)
    },
    setCurrency (currency) {
      this.$store.commit('setCurrency', currency)
    }
  }
}
</script>

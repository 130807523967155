export default `EDITION DATED 03.11.2021

THIS PRIVACY POLICY OF PERSONAL DATA (HEREINAFTER REFERRED TO AS PRIVACY POLICY) APPLIES TO ALL THE INFORMATION THAT ‘ROBUXPIER.СС’ LOCATED ON THE WWW.ROBUXPIER.СС DOMAIN CAN RECEIVE ABOUT USERS WHILE USING THE WEBSITE OF THE ONLINE STORE, PROGRAMS, AND PRODUCTS OF THE ONLINE STORE.

<b>1. TERMS DEFINITION</b>

1.1. THE FOLLOWING TERMS ARE USED IN THIS PRIVACY POLICY:

1.1.1. «ADMINISTRATION OF THE ONLINE STORE WEBSITE» (HEREINAFTER REFERRED TO AS THE WEBSITE ADMINISTRATION) – AUTHORIZED EMPLOYEES ON THE WEBSITE MANAGEMENT ACTING ON BEHALF OF ROBUXPIER.СС, WHICH ORGANIZE AND (OR) PROCESS PERSONAL DATA, AND ALSO DETERMINES THE PURPOSE OF PERSONAL DATA PROCESSING, CONTENT OF PERSONAL DATA FOR PROCESSING, ACTIONS (OPERATIONS) PERFORMED WITH PERSONAL DATA.

1.1.2. «PERSONAL DATA» IMPLIES ANY INFORMATION RELATED DIRECTLY OR INDIRECTLY TO A DEFINED OR IDENTIFIED INDIVIDUAL (PERSONAL DATA SUBJECT).

1.1.3. «PERSONAL DATA PROCESSING» IMPLIES ANY ACTION (OPERATION) OR A SET OF ACTIONS (OPERATIONS) PERFORMED USING AUTOMATION TOOLS OR WITHOUT USING SUCH TOOLS WITH PERSONAL DATA, INCLUDING COLLECTION, RECORD, SYSTEMATIZATION, ACCUMULATION, STORAGE, CLARIFICATION (UPDATE, CHANGE), EXTRACTION, USE, TRANSFER (DISTRIBUTION, PROVISION, ACCESS), DEDICATION, BLOCK, DELETION, AND DESTRUCTION OF PERSONAL DATA.

1.1.4. «PRIVACY OF PERSONAL DATA» IMPLIES A MANDATORY REQUIREMENT FOR THE OPERATOR OR OTHER PERSON WITH ACCESS TO PERSONAL DATA NOT TO ALLOW THEIR DISTRIBUTION WITHOUT THE CONSENT OF THE PERSONAL DATA SUBJECT OR OTHER LEGAL BASIS.

1.1.5. «THE USER OF THE INTERNET STORE WEBSITE» (HEREINAFTER REFERRED TO AS THE USER) IMPLIES A PERSON HAVING ACCESS TO THE WEBSITE THROUGH THE INTERNET NETWORK AND USING THE INTERNET STORE WEBSITE.

1.1.6. «COOKIES» IMPLIES A SMALL DATA FRAGMENT SENT BY THE WEB SERVER AND STORED ON THE USER'S COMPUTER, WHICH THE WEB CLIENT OR WEB BROWSER SENDS TO THE WEB SERVER EVERY TIME IN A HTTP REQUEST WHEN ATTEMPT TO OPEN THE PAGE OF THE CORRESPONDING WEBSITE.

1.1.7. «IP ADDRESS» IMPLIES A UNIQUE NETWORK ADDRESS OF A NODE IN A COMPUTER NETWORK BUILT BY THE IP PROTOCOL.

<b>2. GENERAL PROVISIONS</b>

2.1. USAGE OF THE ONLINE STORE WEBSITE BY THE USER MEANS ACCEPTANCE OF THIS PRIVACY POLICY AND THE TERMS OF PROCESSING OF THE USER'S PERSONAL DATA.

2.2. IF THE USER DOES NOT AGREE WITH THE TERMS OF THE PRIVACY POLICY, THE USER SHOULD STOP USING THE ONLINE STORE WEBSITE.

2.3. THIS PRIVACY POLICY APPLIES ONLY TO ROBUXPIER.СС ONLINE STORE WEBSITE. THE ONLINE STORE DOES NOT CONTROL AND IS NOT RESPONSIBLE FOR THIRD-PARTY WEBSITES THAT CAN BE FOLLOWED BY USERS VIA LINKS AVAILABLE ON THE ONLINE STORE WEBSITE.

2.4. THE WEBSITE ADMINISTRATION DOES NOT CHECK THE PERSONAL DATA ACCURACY PROVIDED BY THE USER OF THE ONLINE STORE WEBSITE.

2.5. ORDER PAYMENT CAN BE MADE BY VISA, MASTER CARD, AND «MIR» BANK CARDS OR THROUGH PAYMENT SYSTEMS APPLE PAY, YANDEX.MONEY, QIWI, OR PAYPAL (THE LAST ONE IS ONLY FOR BUYERS FROM OUTSIDE THE RUSSIAN FEDERATION). TO PAY FOR YOUR PURCHASE, YOU WILL BE REDIRECTED TO THE UNITPAY PAYMENT SYSTEM SERVER WHERE YOU NEED TO ENTER THE REQUIRED DATA. WHEN PAYING WITH A BANK CARD, THE SECURITY OF PAYMENTS IS GUARANTEED BY THE UNITPAY PROCESSING CENTER.

UNITPAY PAYMENT SYSTEM HAS A CONFIRMED CERTIFICATE OF COMPLIANCE WITH THE REQUIREMENTS OF THE STANDARD PCI DSS RELATING STORAGE, PROCESSING AND TRANSFER OF CARD-HOLDER DATA. PCI DSS SECURITY STANDARD IS SUPPORTED BY INTERNATIONAL PAYMENT SYSTEMS INCLUDING MASTERCARD AND VISA, INC. UNITPAY IS ALSO PARTICIPATED IN THE COMPLIANCE CONTROL PCI DSS COMPLIANCE PROCESS (P.D.C.P.) PROGRAM. YOUR CONFIDENTIAL DATA REQUIRED FOR PAYMENT (CARD DETAILS, REGISTRATION DATA, ETC.) DOES NOT GO TO THE INTERNET STORE — THEIR PROCESSING IS CARRIED OUT ON THE SIDE OF THE UNITPAY PROCESSING CENTER AND FULLY PROTECTED.

<b>3. SUBJECT OF THE PRIVACY POLICY</b>

3.1. THIS PRIVACY POLICY ESTABLISHES THE OBLIGATIONS OF THE ADMINISTRATION OF THE ONLINE STORE WEBSITE TO NON-DISCLOSURE AND ENSURE THE PROTECTION OF THE PERSONAL DATA CONFIDENTIALITY PROVIDED BY THE USER AT THE REQUEST OF THE WEBSITE ADMINISTRATION WHEN REGISTERING ON THE ONLINE STORE WEBSITE OR WHEN PLACING AN ORDER FOR PURCHASING GOODS.

3.2. PERSONAL DATA PERMITTED TO PROCESSING UNDER THIS PRIVACY POLICY IS PROVIDED BY THE USER BY FILLING OUT THE REGISTRATION FORM ON ROBUXPIER.СС WEBSITE IN THE TITLE SECTION AND INCLUDING THE FOLLOWING INFORMATION:

3.2.1. SURNAME, NAME OF THE USER

3.2.2. E-MAIL ADDRESS

3.3. THE ONLINE STORE PROTECTS DATA THAT IS AUTOMATICALLY TRANSFERD DURING VIEWING ADVERTISING BLOCKS AND WHEN VISITING THE PAGES:

· IP ADDRESS;

· INFORMATION FROM COOKIES;

· INFORMATION ABOUT THE BROWSER (OR OTHER PROGRAM THAT ALLOWS ACCESS TO DISPLAYING ADVERTISING);

· ACCESS TIME;

· PAGE ADDRESS WITH ADVERTISING BLOCK;

· REFERER (ADDRESS OF THE PREVIOUS PAGE)

3.3.1. DISABLED COOKIES MAY RESULT IN AN IMPOSSIBILITY TO ACCESS TO PARTS OF THE ONLINE STORE WEBSITE REQUIRING AUTHORIZATION.

3.3.2. THE ONLINE STORE COLLECTS STATISTICS ABOUT THE IP ADDRESSES OF ITS VISITORS. THIS INFORMATION IS USED FOR DETECTING AND SOLVING TECHNICAL PROBLEMS, TO CONTROL THE LEGALITY OF CARRIED OUT FINANCIAL PAYMENTS.

3.4. ANY OTHER PERSONAL INFORMATION NOT SPECIFIED ABOVE (PURCHASE HISTORY, BROWSERS AND OPERATING SYSTEMS USED, ETC.) IS A SUBJECT TO RELIABLE STORAGE AND NON-DISTRIBUTION, EXCEPT AS PROVIDED IN SUBCLAUSE 5.2. AND 5.3. OF THIS PRIVACY POLICY.

<b>4. PURPOSE OF COLLECTING USER'S PERSONAL INFORMATION</b>

4.1. THE ADMINISTRATION OF THE INTERNET STORE WEBSITE MAY USE THE USER'S PERSONAL DATA FOR THE FOLLOWING PURPOSES:

4.1.1. IDENTIFY THE USER REGISTERED ON THE INTERNET STORE WEBSITE FOR REMOTE ORDER FROM ROBUXPIER.СС.

4.1.2. PROVIDE THE USER WITH ACCESS TO THE PERSONALIZED RESOURCES OF THE ONLINE STORE WEBSITE.

4.1.3. ESTABLISH  FEEDBACK WITH THE USER, SEND NOTIFICATIONS, AND REQUESTS REGARDING  THE INTERNET STORE WEBSITE USAGE, PROVISION OF SERVICES, AND PROCESS REQUESTS AND APPLICATIONS FROM THE USER.

4.1.4. CONFIRM THE RELIABILITY AND COMPLETENESS OF PERSONAL DATA PROVIDED BY THE USER.

4.1.5. CREATE AN ACCOUNT TO MAKE PURCHASES IF THE USER AGREED TO CREATE ACCOUNT.

4.1.6. NOTIFY THE USER OF THE INTERNET STORE WEBSITE ABOUT THE STATE OF THE ORDER.

4.1.8. PAYMENT PROCESSING AND RECEIVING, PAYMENT DISPUTES.

4.1.9. PROVIDE THE USER WITH EFFECTIVE CUSTOMER AND TECHNICAL SUPPORT IN CASE OF PROBLEMS ASSOCIATED WITH THE USE OF THE ONLINE STORE WEBSITE.

4.1.10. PROVIDE ACCESS TO THE USER TO THE WEBSITES OR SERVICES OF THE PARTNERS OF THE ONLINE STORE FOR OBTAINING PRODUCTS, UPDATES, AND SERVICES.

<b>5. METHODS AND TERMS OF PERSONAL INFORMATION PROCESSING</b>

5.1. PROCESSING OF THE USER'S PERSONAL DATA IS CARRIED OUT WITHOUT LIMITATION OF TIME, BY ANY LEGAL METHOD, AND ALSO THROUGH INFORMATION SYSTEMS OF PERSONAL DATA USING AUTOMATION TOOLS OR WITHOUT USING SUCH TOOLS.

5.2. THE USER AGREES THAT THE WEBSITE ADMINISTRATION IS ENTITLED TO TRANSFER PERSONAL DATA TO THIRD PARTIES, IN PARTICULAR, PAYMENT SYSTEMS WHERE THE USER MADE PAYMENT FOR THE ORDER.

5.3. USER'S PERSONAL DATA MAY BE TRANSFERRED TO AUTHORIZED STATE AUTHORITIES IN THE PROCEDURE ESTABLISHED BY THE LEGISLATION OF THE REPUBLIC OF ESTONIA.

5.4. WHEN PERSONAL DATA IS LOST OR DISCLOSURED, THE WEBSITE ADMINISTRATION INFORMS THE USER ABOUT THE LOSS OR DISCLOSURE OF PERSONAL DATA.

5.5. THE SITE ADMINISTRATION TAKES THE NECESSARY ORGANIZATIONAL AND TECHNICAL MEASURES TO PROTECT THE USER'S PERSONAL INFORMATION FROM UNAUTHORIZED OR ACCIDENTAL ACCESS, DESTRUCTION, MODIFICATION, BLOCKING, COPYING, AND DISTRIBUTION, INCLUDING OTHER ILLEGAL ACTIONS..

5.6. THE WEBSITE ADMINISTRATION AND USER ARE OBLIGED TO TAKE ALL NECESSARY MEASURES TO PREVENT LOSSES OR OTHER NEGATIVE CONSEQUENCES CAUSED BY THE LOSS OR DISCLOSURE OF THE USER'S PERSONAL DATA.

<b>6. OBLIGATIONS OF THE PARTIES</b>

6.1. USER IS OBLIGED TO:

6.1.1. PROVIDE INFORMATION ABOUT PERSONAL DATA REQUIRED TO USE THE ONLINE STORE WEBSITE.

6.1.2. UPDATE, AND SUPPLEMENT THE PROVIDED INFORMATION ABOUT PERSONAL DATA IF CHANGES OCCURRED IN THIS INFORMATION.

6.2. THE WEBSITE ADMINISTRATION IS OBLIGED TO:

6.2.1. USE THE INFORMATION RECEIVED EXCLUSIVELY FOR THE PURPOSES SPECIFIED IN CLAUSE 4 OF THIS PRIVACY POLICY.

6.2.2. KEEP CONFIDENTIAL INFORMATION SECRET, NOT DISCLOSE IT WITHOUT THE PRIOR WRITTEN PERMISSION OF THE USER, AND ALSO NOT SELL, EXCHANGE, PUBLISH, OR DISCLOSE BY OTHER POSSIBLE METHODS OF THE TRANSMITTED PERSONAL DATA OF THE USER EXCEPT SUBCLAUSE 5.2. AND 5.3. OF THIS PRIVACY POLICY.

6.2.3. TAKE PRECAUTIONS TO PROTECT THE PRIVACY OF A USER'S PERSONAL DATA ACCORDING TO THE PROCEDURE USED TO PROTECT THIS TYPE OF INFORMATION IN EXISTING BUSINESS.

6.2.4. BLOCK PERSONAL DATA RELATED TO THE RELEVANT USER FROM THE MOMENT USER APPLIED OR REQUESTED OR HIS LEGAL REPRESENTATIVE OR THE AUTHORIZED BODY FOR THE PROTECTION OF THE RIGHTS OF PERSONAL DATA SUBJECTS FOR THE VERIFICATION PERIOD, IN CASE OF IDENTIFICATION OF FALSE PERSONAL DATA OR ILLEGAL ACTIONS.

<b>7. RESPONSIBILITIES OF THE PARTIES</b>

7.1. THE WEBSITE ADMINISTRATION THAT FAILED ITS OBLIGATIONS SHALL BE RESPONSIBLE FOR LOSSES INCURRED BY THE USER BECAUSE OF ILLEGAL USE OF PERSONAL DATA, IN ACCORDANCE WITH THE LEGISLATION OF THE REPUBLIC OF ESTONIA.

7.2. IN CASE OF LOSS OR DISCLOSURE OF CONFIDENTIAL INFORMATION, THE WEBSITE ADMINISTRATION IS NOT RESPONSIBLE IF THIS CONFIDENTIAL INFORMATION:

7.2.1. BECAME THE PUBLIC PROPERTY UNTIL ITS LOSS OR DISCLOSURE.

7.2.2. WAS PROVIDED BY A THIRD PARTY BEFORE ITS RECEIPT BY THE WEBSITE ADMINISTRATION.

7.2.3. WAS DISCLOSED WITH THE CONSENT OF THE USER.

<b>8. SETTLEMENT OF DISPUTES</b>

8.1. BEFORE APPLYING TO THE COURT WITH A CLAIM FOR DISPUTES ARISING FROM RELATIONS BETWEEN THE USER OF THE INTERNET STORE WEBSITE AND THE WEBSITE ADMINISTRATION, IT IS OBLIGATORY TO SUBMIT A CLAIM (WRITTEN PROPOSAL FOR VOLUNTARY SETTLEMENT OF THE DISPUTE).

8.2 .WITHIN 30 CALENDAR DAYS FROM THE DATE OF RECEIVING THE CLAIM, THE CLAIM'S RECIPIENT NOTIFIES THE APPLICANT OF THE CLAIM IN WRITING ON THE RESULTS OF THE CONSIDERATION OF THE CLAIM..

8.3. IF PARTIES FAILED TO REACH AN AGREEMENT, THE DISPUTE MAY BE SUBMITTED TO THE JUDICIAL AUTHORITY IN ACCORDANCE WITH THE CURRENT LEGISLATION OF THE REPUBLIC OF ESTONIA

<b>9. ADDITIONAL TERMS AND CONDITIONS</b>

9.1. THE WEBSITE ADMINISTRATION IS ENTITLED TO MAKE AMENDS TO THIS PRIVACY POLICY WITHOUT USER CONSENT.

9.2. THE NEW PRIVACY POLICY COMES INTO FORCE FROM THE TIME IT IS PLACED ON THE WEBSITE OF THE INTERNET STORE, UNLESS OTHERWISE PROVIDED IN THE NEW VERSION OF THE PRIVACY POLICY.

9.3. THE APPLICABLE PRIVACY POLICY IS ON THE FOLLOWING PAGE: HTTPS://ROBUXPIER.СС/PRIVACY`.split(new RegExp(/\s+\r?\n/))

<template>
  <div class="slider">
    <div class="slider-circles">
      <div class="circle active" data-index="0"></div>
      <div class="circle" data-index="1"></div>
      <div class="circle" data-index="2"></div>
    </div>
    <div class="slider__wrapper">
      <div class="slider__wrapper-lenta">
        <div class="slider__wrapper-item">
          <div class="slider__wrapper-item__content">
            <p class="slider__wrapper-item__title">{{ $t('slider.slide1.title') }}</p>
            <p class="slider__wrapper-item__text">{{ $t('slider.slide1.content') }}</p>
            <router-link to="/methods" class="slider__wrapper-item__btn">{{ $t('actions.buyRobux') }}</router-link>
          </div>
          <div class="slider__wrapper-item__img">
            <img src="../../assets/images/slider/img1.png" alt="">
          </div>
        </div>
        <div class="slider__wrapper-item">
          <div class="slider__wrapper-item__content">
            <p class="slider__wrapper-item__title">{{ $t('slider.slide2.title') }}</p>
            <p class="slider__wrapper-item__text">{{ $t('slider.slide2.content') }}</p>
            <router-link to="/methods" class="slider__wrapper-item__btn">{{ $t('actions.buyRobux') }}</router-link>
          </div>
          <div class="slider__wrapper-item__img">
            <img src="../../assets/images/slider/img3.png" alt="">
          </div>
        </div>
        <div class="slider__wrapper-item">
          <div class="slider__wrapper-item__content">
            <p class="slider__wrapper-item__title">{{ $t('slider.slide3.title') }}</p>
            <p class="slider__wrapper-item__text">{{ $t('slider.slide3.content') }}</p>
            <router-link to="/methods" class="slider__wrapper-item__btn">{{ $t('actions.buyRobux') }}</router-link>
          </div>
          <div class="slider__wrapper-item__img">
            <img src="../../assets/images/slider/img2.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './slider.scss'
import Slider from '@/utils/slider'

export default {
  name: 'Slider',
  mounted () {
    Slider()
  }
}
</script>

import policy from '../policy/en'
import terms from '../terms/en'

export default {
  navigation: {
    title: 'NAVIGATION',
    home: "PAGE D'ACCEUIL",
    buyRobux: 'ACHETER ROBUX',
    lk: 'BUREAU PERSONNEL',
    vk: 'Groupe VK',
    discord: 'Discord',
    telegram: 'Telegram',
    instagram: 'Instagram',
    tiktok: 'Tiktok',
    dropdown: {
      history: 'Histoire',
      changePassword: 'Modifier le mot de passe',
      refill: 'Recharger',
      logout: 'Sortie'
    }
  },
  general: {
    warning: 'Attention!',
    error: 'Erreur',
    gotIt: 'Compris',
    confirm: 'CONFIRMATION',
    or: 'ou',
    link: 'lieu',
    transfer: 'transfert',
    policy: 'POLITIQUE DE CONFIDENTIALITÉ',
    terms: 'ACCORD UTILISATION',

    errors: {
      internal: 'Erreur interne, veuillez réessayer plus tard',
      server: 'Erreur de serveur'
    }
  },
  actions: {
    continue: 'Continuer',
    submit: 'VÉRIFIER',
    buyRobux: 'ACHETER ROBUX',
    openDiscord: 'ALLER SUR DISCORD',
    openInstruction: 'ALLER À INSTRUCTION',
    createAccount: 'Créer un compte',
    login: 'CONNEXION AU COMPTE',
    loginVK: 'Se connecter avec VK',
    signin: 'Connexion',
    enterPassword: 'Entrez-le',
    skip: 'Sauter',
    buy: 'Acquérir',
    buyOrder: 'Acheter',
    tryAgain: 'Réessayer',
    create: 'Create',
    ready: 'Ready',
    edit: 'Edit'
  },
  payment: {
    refill: 'Réapprovisionnement du solde',
    total: 'Total',
    toPay: 'Payable',
    pay: 'PAYER',
    makeOrder: 'Payer la commande',
    otherPaymentMethods: 'Autres modes de paiement',
    enterSum: 'Entrez le montant de la recharge',
    commission: 'Commission',
    notEnough: 'Vous avez pas assez de fonds pour payer!',
    problems: 'Si vous rencontrez des problèmes, écrivez à notre {support}',
    support: 'service support',
    selectPaymentMethod: 'SÉLECTIONNER LE MÉTHODE DE PAIEMENT'
  },
  order: {
    status: 'STATUT DE LA COMMANDE',
    wait: 'VEUILLEZ ATTENDRE la commande',
    waitTime: 'IL RESTE',
    code: 'Le code doit comporter 6 chiffres',
    mailCode: 'Entrez le code email',
    makeNewOrder: 'Faire une autre commande',
    table: {
      nickname: 'PSEUDO DE JEU',
      count: 'NOMBRE DE R$',
      total: 'TOTAL, ROUBLES'
    },
    descriptions: {
      wait: 'Veuillez attendre la commande',
      notEnoughFunds: 'Erreur. Le site a pas assez de fonds!',
      systemError: 'Erreur système!',
      checkVip: 'Vérifier les paramètres VIP ROBLOX!',
      connectionError: 'Erreur de connexion à ROBLOX!',
      success: 'Commande terminée avec succès!',
      checkGamepass: 'Vérifiez vos paramètres Gamepass!'
    }
  },
  auth: {
    authorization: 'Autorisation',
    registration: 'Inscription',
    recovery: 'RÉCUPÉRATION',
    change: 'MODIFIER LE MOT DE PASSE',

    inputs: {
      email: 'Entrez votre e-mail',
      login: 'Entrez votre identifiant',
      loginOrEmail: 'Entrez votre identifiant ou votre e-mail',
      currentPassword: 'Mot de passe actuel',
      newPassword: 'Nouveau mot de passe',
      repeatNewPassword: 'Répéter le nouveau mot de passe',
      password: 'Entrer le mot de passe',
      passwordSubmit: 'Confirmer le mot de passe'
    },

    errors: {
      email: 'Email invalide!',
      incorrectPassword: 'Le mot de passe est incorrect!',
      empty: 'Le champ est vide',
      nicknameIsTooSmall: 'Vous devez saisir un nom au moins 4 caractères',
      inactiveEmail: 'Email invalide',
      passwordIsTooSmall: 'Votre mot de passe doit comporter au moins 6 caractères',
      passwordNotMatch: 'Les mots de passe ne correspondent pas',
      oneUppercase: 'Votre mot de passe doit contenir au moins une lettre majuscule',
      userExist: 'Utilisateur avec ces données existe déjà',
      checkEmail: 'Assurez-vous que votre adresse email/nom utilisateur est correct',
      checkPassword: 'Assurez-vous que votre mot de passe est correct',
      changePasswordIncorrect: 'Le mot de passe doit comporter de 6 à 18 caractères et être composé des lettres « A-z » !'
    },

    welcome: {
      title: 'BIENVENUE',
      content: 'INSCRIVEZ-VOUS SUR NOTRE SITE ET COMMENCEZ À ACHETER ROBUX EN TOUTE SÉCURITÉ, RAPIDEMENT ET EN TOUTE SÉCURITÉ'
    },

    forget: 'Mot de passe oublié?',
    remember: 'Avez-vous retenu votre mot de passe?',
    agreement: 'Je accepte {policy}',
    privacyPolicy: 'politique de confidentialité',
    haveAccount: 'Avez-vous déjà un compte?',
    codeSent: 'Un code de récupération de mot de passe a été envoyé à {email}!',
    enterCode: 'Entrez le code à 6 chiffres:',
    requestCode: 'Demander à nouveau',

    changePasswordSuccess: {
      title: 'Excellent!',
      content: 'Vous avez changé votre mot de passe avec succès! Assurez-vous de vous rappeler...'
    }
  },
  slider: {
    slide1: {
      title: '#1 ACHETEZ ROBUX EN TOUTE SÉCURITÉ!',
      content: 'Toutes les devises du jeu obtenues légalement auprès des développeurs de jeux'
    },
    slide2: {
      title: '#2 MEILLEURS TARIFS!',
      content: 'Uniquement dans notre magasin que vous pouvez acheter des robux aux prix les plus bas et les obtenir garantis!'
    },
    slide3: {
      title: '#3 LES CLIENTS NOUS FONT CONFIANCE!',
      content: 'Notre magasin existe depuis trois ans et pendant ce temps il a imposé bien son image!'
    }
  },
  faq: {
    title: 'VOULEZ-VOUS ACHETER ROBUX?',
    content: 'PRIX EXCEPTIONNELS, EXPÉDITION RAPIDE!',
    items: {
      item1: {
        index: '01',
        title: 'VEUILLEZ COMMANDER',
        content: 'Sélectionnez le nombre de robux à acheter et votre compte roblox'
      },
      item2: {
        index: '02',
        title: 'Payez',
        content: 'Payez la commande de manière pratique'
      },
      item3: {
        index: '03',
        title: 'OBTENEZ ROBUX',
        content: 'Obtenez robux sur votre compte de jeu dans les 5 jours!'
      }
    }
  },
  help: {
    title: 'Difficulté à acheter robux?',
    text1: 'Notre support vous aidera certainement avec eux sur le serveur dans Discord ou dans des messages Instagram personnels!',
    text2: 'Vous pouvez également utiliser les instructions pour'
  },
  footer: {
    privacy: 'POLITIQUE DE CONFIDENTIALITÉ',
    terms: 'ACCORD UTILISATION',
    contacts: 'INFOS DE CONTACT'
  },
  methods: {
    title: 'QUELLE MÉTHODE VOULEZ-VOUS UTILISER POUR ACHETER?',
    hintError: {
      title: 'Erreur!',
      content: 'Vous avez déjà Roblox Premium!'
    },
    method: 'Méthode',
    robux: 'Robux',
    premium: 'Premium',
    transfer: 'Transferts',
    logpass: 'log+pass',
    available: 'Disponible en stock',
    notAvailable: 'Pas en stock'
  },
  cookie: {
    whatIsCookie: "Qu'est-ce qu'un COOKIE?",
    checkInstruction: 'VOIR LES INSTRUCTIONS RELATIVES AUX COOKIES',
    enter: 'Entrez le cookie',
    description: {
      part1: "Les cookies eux-mêmes ne sont pas dangereux - ce sont des fichiers de texte brut. Ils ne peuvent pas démarrer de processus sur l'ordinateur et interagissent en général avec le système d'exploitation. Mais ils peuvent être interceptés ou volés pour suivre vos activités en ligne précédentes ou vous connecter à vos comptes sans autorisation.",
      part2: "D'habitude, les informations écrites dans les cookies sont cryptées avant l'envoi, et les cookies eux-mêmes sont transmis via le protocole HTTPS. Cela aide à protéger les données des utilisateurs, mais c'est au développeur du site de mettre en œuvre le cryptage et de l'envoyer en toute sécurité. Les visiteurs ne peuvent qu'espérer que tout est correctement configuré. Et l'utilisateur peut uniquement empêcher le navigateur utiliser des cookies ou les supprimer de temps à autre."
    }
  },
  transfer: {
    robuxDelivery: 'Robux sera crédité dans les 5 jours (120 heures)',
    available: 'Disponible:',
    robuxCount: 'Nombre de robux',
    minumum: 'Minimum à partir de {min} R$',
    nickname: 'Pseudo du jeu',
    notFound: 'Rien trouvé',
    checkPlaces: 'VEUILLEZ VÉRIFIER QUE VOUS AVEZ AU MOINS 1 LIEU PUBLIC',
    vipPrice: 'Définir le prix du serveur VIP sur {price}',
    changePrice: 'Modifier le prix',
    orderSuccess: 'Vous pouvez suivre la réception de robux sur votre compte {here}',
    orderError: 'Les fonds ont été retournés au solde du compte ROBUXPIER. Essayer à nouveau :)',
    here: 'ici',
    gamepass: {
      checkData: 'Check the correctness of the data in Gamepass!',
      creating: 'CREATING',
      settingUp: 'SETUP',
      createGamepass: 'Create a Game-pass, you can use <a href="https://backend-pier.com/luckyteam.jpg" target="_blank">this </a>image',
      checkInstruction: 'View the instructions and set the price <span>{price} R$</span>'
    },
    errors: {
      insufficientBalance: 'Solde insuffisant',
      notAuthorized: 'Vous êtes pas autorisé',
      serverError: 'Erreur de serveur',
      robuxNotAvailable: 'Montant de robux non disponible à achat',

      priceNotNumber: 'Le prix doit être un nombre',
      robuxMinimum: 'Impossible de commander moins de {robux} robux',
      halfBalance: 'Impossible de commander plus de la moitié de solde: {balance} de robux',
      robuxMustDivide: 'R$ doit être divisible par {orders}. Essayez <span class="min">{closestMin}R$</span> ou <span class="max">{closestMax}R$</span>'
    },
    selectYourPlace: 'Sélectionnez votre'
  },
  logpass: {
    enterNickname: 'ENTRER LE PSEUDO',
    buyRobuxTitle: 'ACHAT DE robux',
    codes: {
      code_request: 'Veuillez saisir le code qui vous a été envoyé',
      order_awaiting: 'Attendre la commande',
      code_request_awaiting: 'En attente de demande de code par e-mail',

      password_error: 'Mot de passe invalide dans ROBLOX',
      code_error: 'Code de vérification en 2 étapes non valide provenant de e-mail',
      cookie_error: 'Erreur. Données autorisation invalides',
      email_timeout_error: 'Vous avez pas fourni de code! Réessayez et essayez entrer le code dès que possible!',
      error: 'Erreur. Essayer à nouveau',
      success: 'Commande terminée avec succès',
      waiting: 'Attendre la commande'
    }
  },
  premium: {
    buyPremium: 'ACHAT DE PREMIUM',
    alreadyExist: 'Vous avez déjà Roblox Premium!',
    subscribe: {
      title: '{percent} AUX ROBUX!',
      subtitle: 'Obtenez plus de robux avec un abonnement Premium '
    },
    getRbx: 'Obtenez {rbx} au compte',
    buyWithProfit: 'ACHETEZ AVEC PROFIT!',
    currencyPerMonth: '{currency} pour mois',
    toPay: 'Total à payer: ',
    premiumTime: 'Abonnement Premium est disponible que pour {time}',
    oneMonth: '1 mois sans renouvellement automatique',
    enterPromocode: 'Entrez le code promotionnel',
    ordering: 'VEUILLEZ COMMANDER',
    mailCode: 'ATTENDRE LA DEMANDE DE CODE EMAIL',

    correctSum: 'Entrez le montant correct',
    minRefillSum: 'Le montant de la recharge doit être au moins 5 roubles'
  },
  supportChat: {
    hint: 'Avez-vous une question?',
    title: 'SOUTIEN AUX JOUEURS',
    operator: 'Votre assistant',
    actions: 'Options des actions',
    anket: 'Présentez-vous pour contacter opérateur. Vous obtiendrez les réponses aux questions liées à RobuxPier.',
    name: 'Nom',
    email: 'E-mail',
    vk: 'Lien vers Vkontakte',
    send: 'Envoyer',
    enterMessage: 'Entrer un message...'
  },
  qiwi: {
    details: 'DETAILS DE LA RECHARGE',
    passport: 'Avez-vous un passeport russe?',
    noPassport: 'Comme vous avez pas de passeport russe, vous ne pourrez passer la vérification dans le système de paiement Qiwi.',
    hasPassport: 'Il faut vérifier sur le site Web de Qiwi pour obtenir des frais moins élevés.',
    noPassportCommission: 'La commission sera de 7,5%',
    hasPassportCommission: 'La commission sera de 3%',
    yes: 'Oui',
    no: 'Non'
  },
  history: {
    nickname: 'Pseudo du jeu',
    sumRobux: 'Montant, R$',
    sumRub: 'montant',
    status: 'Statut',
    description: 'Description',
    timer: 'Minuterie',

    error: 'Erreur',
    wait: 'Attente',
    success: 'Succès',
    orderCount: 'Sont affichés {order} de {length} inscriptions'
  },
  policy,
  terms
}

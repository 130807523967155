var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"history"},[(_vm.$store.state.change_password)?_c('div',{staticClass:"change-password"},[_c('ChangePassword')],1):_vm._e(),_c('div',{staticClass:"wrapper"},[_c('Navigation')],1),_c('div',{staticClass:"history-content"},[(_vm.loading)?_c('Loader'):_vm._e(),_c('div',{staticClass:"history-content__header"},[_c('div',{staticClass:"history-content__header__block active"},[_c('p',[_vm._v(_vm._s(_vm.$t("general.transfer")))]),_c('small',[_vm._v(_vm._s(_vm.totalOrders))])])]),_c('div',{staticClass:"history-table"},[(_vm.ordersLoading)?_c('Loader'):_vm._e(),_c('div',{staticClass:"history-table__header"},[_c('p',{staticClass:"id"},[_vm._v("ID")]),_c('p',{staticClass:"nickname"},[_vm._v(_vm._s(_vm.$t("history.nickname")))]),_c('p',{staticClass:"sum-robux"},[_vm._v(_vm._s(_vm.$t("history.sumRobux")))]),_c('p',{staticClass:"sum-rub"},[_vm._v(_vm._s(_vm.$t("history.sumRub")))]),_c('p',{staticClass:"status"},[_vm._v(_vm._s(_vm.$t("history.status")))]),_c('p',{staticClass:"description"},[_vm._v(_vm._s(_vm.$t("history.description")))]),_c('p',{staticClass:"timer"},[_vm._v(_vm._s(_vm.$t("history.timer")))])]),_c('div',{staticClass:"history-table__rows"},_vm._l((_vm.orders),function(order){return _c('div',{key:order._id,staticClass:"history-table__row"},[_c('p',{staticClass:"id"},[_vm._v(_vm._s(order._id.slice(-4)))]),_c('p',{staticClass:"nickname"},[_vm._v(_vm._s(order.username))]),_c('p',{staticClass:"sum-robux"},[_vm._v(_vm._s(order.robux_amount)+" R$")]),_c('p',{staticClass:"sum-rub"},[_vm._v(" "+_vm._s(_vm.getOrderTotal(order))+" "+_vm._s(_vm.$store.getters.getCurrencySign(order.currency.toLowerCase()))+" ")]),_c('p',{staticClass:"sum-rub"},[_vm._v(_vm._s(order.amount.rub.toFixed(2))+" ₽")]),_c('p',{staticClass:"status"},[(
                order.status === 'error' || order.status === 'system_error'
              )?_c('span',{staticClass:"error"},[_vm._v(_vm._s(_vm.$t("history.error")))]):_vm._e(),(
                ['waiting', 'paid', 'code', 'robuxship'].includes(
                  order.status
                )
              )?_c('span',{staticClass:"waiting"},[_vm._v(_vm._s(_vm.$t("history.wait")))]):_vm._e(),(order.status === 'success')?_c('span',{staticClass:"success"},[_vm._v(_vm._s(_vm.$t("history.success")))]):_vm._e()]),_c('p',{staticClass:"description"},[_vm._v(_vm._s(_vm.getOrderDescription(order)))]),_c('p',{staticClass:"timer"},[_vm._v(_vm._s(_vm.parseDate(order._id)))])])}),0)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.totalOrders > 0),expression:"totalOrders > 0"}],staticClass:"history-footer"},[_c('div',{staticClass:"history-footer__nav"},[_c('div',_vm._g({staticClass:"history-footer__nav__left"},
            _vm.currentPage - 1 <= 0
              ? {}
              : { click: function () { return _vm.changeOrders(_vm.currentPage - 1); } }
          ),[_c('img',{attrs:{"src":require("./../../assets/images/history/left-arrow.svg"),"alt":"left"}})]),_c('div',{staticClass:"history-footer__nav__center"},_vm._l((_vm.totalPages),function(i){return _c('p',{key:i,class:{ active: i === _vm.currentPage },on:{"click":function($event){return _vm.changeOrders(i)}}},[_vm._v(" "+_vm._s(i)+" ")])}),0),_c('div',_vm._g({staticClass:"history-footer__nav__right"},
            _vm.currentPage + 1 > _vm.totalPages
              ? {}
              : { click: function () { return _vm.changeOrders(_vm.currentPage + 1); } }
          ),[_c('img',{attrs:{"src":require("./../../assets/images/history/right-arrow.svg"),"alt":"right"}})])]),_c('div',{staticClass:"history-footer__info"},[_c('i18n',{attrs:{"path":"history.orderCount","tag":"p"},scopedSlots:_vm._u([{key:"order",fn:function(){return [_vm._v(" "+_vm._s(_vm.orders.length)+" ")]},proxy:true},{key:"length",fn:function(){return [_vm._v(" "+_vm._s(_vm.totalOrders)+" ")]},proxy:true}])})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="order-succesful">
    <div class="order-succesful__main">
      <div class="order-succesful__main__to-left" @click="changeOrder(-1)">
        <svg width="21" height="36" viewBox="0 0 21 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.09011 16.1164L16.1074 1.12564C16.354 0.876957 16.6475 0.679573 16.9708 0.544872C17.2941 0.410172 17.6409 0.34082 17.9912 0.34082C18.3414 0.34082 18.6882 0.410172 19.0115 0.544872C19.3348 0.679573 19.6283 0.876957 19.8749 1.12564C20.3691 1.62275 20.6465 2.29522 20.6465 2.99616C20.6465 3.69711 20.3691 4.36957 19.8749 4.86669L6.74148 18.1328L19.8749 31.2663C20.3691 31.7634 20.6465 32.4359 20.6465 33.1368C20.6465 33.8378 20.3691 34.5102 19.8749 35.0073C19.6292 35.258 19.3362 35.4575 19.0128 35.5941C18.6895 35.7307 18.3422 35.8018 17.9912 35.8033C17.6401 35.8018 17.2929 35.7307 16.9695 35.5941C16.6461 35.4575 16.3531 35.258 16.1074 35.0073L1.09011 20.0166C0.820793 19.7682 0.60586 19.4666 0.458851 19.131C0.311844 18.7953 0.235949 18.4329 0.235949 18.0665C0.235949 17.7001 0.311844 17.3376 0.458851 17.002C0.60586 16.6664 0.820793 16.3648 1.09011 16.1164Z" fill="#5595F9" fill-opacity="0.29"/>
        </svg>
      </div>
      <div class="order-succesful__main__inf">
        <div class="order-succesful__main__inf__num">#{{ currentIndex + 1 }}</div>
        <Logo dark class="order-succesful__main__inf__logo" />
        <div class="order-succesful__main__inf__icon" v-if="data.orders[currentIndex].status === 'success'">
          <img :src="require('./../../../assets/images/transfer/status-yes.png')" alt="">
        </div>
        <div class="order-succesful__main__inf__icon" v-if="data.orders[currentIndex].status === 'error' || data.orders[currentIndex].status === 'system_error'">
          <img :src="require('./../../../assets/images/transfer/status-no.png')" alt="">
        </div>
        <div class="order-waiting__main__inf__timer" v-if="['waiting', 'paid', 'code', 'robuxship'].includes(data.orders[currentIndex].status)">
          <img src="@/assets/images/transfer/spiner.png" alt="">
          <p>{{ displayTimer() }}</p>
        </div>
        <p class="order-succesful__main__inf__wait">{{ getOrderDescription(data.orders[currentIndex]) }}</p>
        <div class="order-succesful__main__inf__table">
          <div class="order-succesful__main__inf__table__head">
            <div class="order-succesful__main__inf__table__head__atr">{{ $t('order.table.nickname') }}</div>
            <div class="order-succesful__main__inf__table__head__atr">{{ $t('order.table.count') }}</div>
            <div class="order-succesful__main__inf__table__head__atr">{{ $t('order.table.total') }}</div>
          </div>
          <div class="order-succesful__main__inf__table__body">
            <div class="order-succesful__main__inf__table__body__obj"><p>{{ data.orders[currentIndex].username }}</p></div>
            <div class="order-succesful__main__inf__table__body__obj"><p>{{ data.orders[currentIndex].robux_amount.toFixed(2) }} R$</p></div>
            <div class="order-succesful__main__inf__table__body__obj"><p>{{ getOrderTotal(data.orders[currentIndex]) }} {{ $store.getters.getCurrencySign(data.orders[currentIndex].currency.toLowerCase()) }}</p></div>
          </div>
        </div>
        <i18n path="transfer.orderSuccess" tag="p" class="order-succesful__main__inf__text" v-if="data.orders[currentIndex].status === 'success'">
          <template v-slot:here>
            <a href="https://vk.com/away.php?to=https%3A%2F%2Fwww.roblox.com%2Ftransactions"><span>{{ $t('transfer.here') }}</span></a>
          </template>
        </i18n>

        <p class="order-succesful__main__inf__text" v-if="data.orders[currentIndex].status === 'error'">{{ $t('transfer.orderError') }}</p>
        <div class="order-succesful__main__inf__btn" @click="changeModal">
          <p class="order-succesful__main__inf__btn__plus">+</p>
          <p class="order-succesful__main__inf__btn__text">{{ $t('order.makeNewOrder') }}</p>
        </div>
      </div>
      <div class="order-succesful__main__to-right" @click="changeOrder(1)">
        <svg width="21" height="36" viewBox="0 0 21 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.09011 16.1164L16.1074 1.12564C16.354 0.876957 16.6475 0.679573 16.9708 0.544872C17.2941 0.410172 17.6409 0.34082 17.9912 0.34082C18.3414 0.34082 18.6882 0.410172 19.0115 0.544872C19.3348 0.679573 19.6283 0.876957 19.8749 1.12564C20.3691 1.62275 20.6465 2.29522 20.6465 2.99616C20.6465 3.69711 20.3691 4.36957 19.8749 4.86669L6.74148 18.1328L19.8749 31.2663C20.3691 31.7634 20.6465 32.4359 20.6465 33.1368C20.6465 33.8378 20.3691 34.5102 19.8749 35.0073C19.6292 35.258 19.3362 35.4575 19.0128 35.5941C18.6895 35.7307 18.3422 35.8018 17.9912 35.8033C17.6401 35.8018 17.2929 35.7307 16.9695 35.5941C16.6461 35.4575 16.3531 35.258 16.1074 35.0073L1.09011 20.0166C0.820793 19.7682 0.60586 19.4666 0.458851 19.131C0.311844 18.7953 0.235949 18.4329 0.235949 18.0665C0.235949 17.7001 0.311844 17.3376 0.458851 17.002C0.60586 16.6664 0.820793 16.3648 1.09011 16.1164Z" fill="#5595F9" fill-opacity="0.29"/>
        </svg>
      </div>
    </div>
    <div class="order-succesful__btn" @click="changeModal">
      <p class="order-succesful__btn__plus">+</p>
      <p class="order-succesful__btn__text">{{ $t('order.makeNewOrder') }}</p>
    </div>
  </div>
</template>

<script>
import './ordersuccesful.scss'
import './orderwaiting.scss'
import Logo from '@/components/Logo/Logo.vue'

export default {
  name: 'OrderSuccesful',
  props: ['data'],
  inject: ['currencyMap'],
  components: {
    Logo
  },
  data () {
    return {
      currentIndex: 0,
      currentTimer: null,
      timer: null,
      intervalId: null,
      orderIId: null
    }
  },
  created () {
    this.$store.dispatch('authorization', this.$cookies.get('access_token'))

    this.orderIId = setInterval(() => {
      this.getOrders()
    }, 25000)
  },
  mounted () {
    this.setTimer()
  },
  destroyed () {
    clearInterval(this.orderIId)
  },
  methods: {
    changeModal () {
      this.$emit('changeModal', {
        modal: 'MainForm'
      })
    },
    getOrders () {
      this.$store.dispatch('authorization', this.$cookies.get('access_token'))

      const requestHeaders = new Headers({
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      })

      const requestParams = {
        method: 'GET',
        headers: requestHeaders
      }

      fetch('https://transfer.backend-pier.com/active_orders', requestParams)
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            this.data.orders = data.orders

            if (this.data.orders.length > 0) {
              this.currentTransfer = 'TransferOrder'
            }
          }
        })
        .catch((err) => console.log(err))
    },
    getOrderTotal (order) {
      return order.amount[order.currency.toLowerCase()].toFixed(2)
    },
    getOrderDescription (order) {
      const code = order.code_description
      const texts = {
        0: this.$t('order.descriptions.wait'),
        1: this.$t('order.descriptions.notEnoughFunds'),
        2: this.$t('order.descriptions.systemError'),
        3: this.$t('order.descriptions.checkVip'),
        4: this.$t('order.descriptions.connectionError'),
        5: this.$t('order.descriptions.success'),
        6: this.$t('order.descriptions.checkGamepass')
      }

      return texts[code]
    },
    setTimer () {
      if (this.intervalId) {
        clearInterval(this.intervalId)
      }

      const orderDate = new Date(this.data.orders[this.currentIndex].date)
      orderDate.setHours(orderDate.getHours() + 1)
      this.timer = orderDate - Date.now()

      this.intervalId = setInterval(() => {
        this.timer -= 1000
      }, 1000)
    },
    displayTimer () {
      if (this.timer <= 0) {
        return '00:00:00'
      }

      const formatedHours = ('0' + String(Math.trunc(this.timer / 1000 / 60 / 60))).slice(-2)
      const formatedMinutes = ('0' + String(Math.trunc(this.timer / 1000 / 60 % 60))).slice(-2)
      const formatedSeconds = ('0' + String(Math.trunc(this.timer / 1000 % 60 % 60))).slice(-2)

      return `${formatedHours}:${formatedMinutes}:${formatedSeconds}`
    },
    changeOrder (direction) {
      if (direction === 1) {
        this.currentIndex = this.currentIndex + 1 >= this.data.orders.length ? 0 : this.currentIndex + 1
      } else if (direction === -1) {
        this.currentIndex = this.currentIndex - 1 < 0 ? this.data.orders.length - 1 : this.currentIndex - 1
      }

      this.setTimer()
    }
  }
}
</script>

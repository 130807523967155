<template>
  <div class="burgerMenu" :class="{ open: $store.state.isOpenBurgerMenu }">
    <div class="burgerMenu-wrapper">
      <div class="burgerMenu-wrapper__header">
        <p class="burgerMenu-wrapper__header-title">
          {{ $t("navigation.title") }}
        </p>
        <img
          @click="$store.state.isOpenBurgerMenu = false"
          class="burgerMenu-wrapper__header-close"
          src="../../assets/images/burgerMenu/close.svg"
          alt="Close"
        />
      </div>
      <div class="burgerMenu-wrapper__content">
        <ul class="burgerMenu-wrapper__content-list">
          <li class="burgerMenu-wrapper__content-list__item">
            <router-link to="/">{{ $t("navigation.home") }}</router-link>
          </li>
          <li class="burgerMenu-wrapper__content-list__item">
            <router-link to="/methods">{{
              $t("navigation.buyRobux")
            }}</router-link>
          </li>
          <li class="burgerMenu-wrapper__content-list__item" v-if="isRussian">
            <a href="https://vk.com/robuxpier" target="_blank">{{
              $t("navigation.vk")
            }}</a>
          </li>
          <li class="burgerMenu-wrapper__content-list__item">
            <a :href="discordUrl" target="_blank">{{
              $t("navigation.discord")
            }}</a>
          </li>
          <li class="burgerMenu-wrapper__content-list__item">
            <a href="https://t.me/robuxpier" target="_blank">{{
              $t("navigation.telegram")
            }}</a>
          </li>
        </ul>
      </div>
      <Selectables :mobile="true" />

      <div class="burgerMenu-wrapper__footer">
        <router-link to="/">
          <img src="../../assets/images/burgerMenu/yakor.svg" alt="Logo" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import './burgermenu.scss'
import Selectables from '@/components/Navigation/Selectables/Selectables.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'BurgerMenu',
  components: {
    Selectables
  },
  mounted () {
    const links = document.querySelectorAll(
      '.burgerMenu-wrapper__content-list__item'
    )

    links.forEach((link) => {
      link.addEventListener('click', () => {
        this.$store.state.isOpenBurgerMenu = false
      })
    })
  },
  computed: {
    ...mapGetters(['language']),
    isRussian () {
      return this.language === 'ru'
    },
    discordUrl () {
      const ruUrl = 'https://discord.gg/robuxpier'
      const engUrl = 'https://discord.gg/zckunR3EnD'
      return this.isRussian ? ruUrl : engUrl
    }
  }
}
</script>

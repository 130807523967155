<template>
  <div class="payment">
    <div class="payment__main">
      <Logo dark class="payment__main__logo" />
      <div class="payment__main__form">
        <div class="payment__main__form__input">
          <div class="payment__main__form__input__img"><img src="@/assets/images/transfer/people.svg" alt=""></div>
            <div class="payment__main__form__input__in">
              <input type="text" :value="data.nickname" readonly>
            </div>
        </div>
      </div>
      <div class="payment__main__form">
        <div class="payment__main__form__input">
          <div class="payment__main__form__input__img"><img src="@/assets/images/transfer/coin1.svg" alt=""></div>
            <div class="payment__main__form__input__in">
              <input type="text" :value="data.totalRobux + ' R$'" readonly>
            </div>
        </div>
      </div>
      <div class="payment__main__form">
        <div class="payment__main__form__input">
          <div class="payment__main__form__input__img"><img src="@/assets/images/transfer/rub.svg" alt=""></div>
            <div class="payment__main__form__input__in">
              <input type="text" :value="data.moneyTotal().toFixed(2) + ` ${currencyMap[$store.state.currency]}`" readonly>
            </div>
        </div>
      </div>
      <button class="payment__main__btn-pay" @click="changeModal">{{ $t('payment.pay') }}</button>
      <p class="payment__main__result">{{ $t('payment.toPay') }}: <span>{{ data.moneyTotal().toFixed(2) }} {{ currencyMap[$store.state.currency] }}</span></p>
    </div>
  </div>
</template>

<script>
import './payment.scss'
import Logo from '@/components/Logo/Logo.vue'

export default {
  name: 'Payment',
  props: ['data'],
  inject: ['currencyMap'],
  components: {
    Logo
  },
  methods: {
    changeModal () {
      let emitParams = {}
      if (!(this.$store.state.isAuth)) {
        this.$store.state.sign_in = true
      } else {
        emitParams = { modal: 'TransferApproved' }
        this.$emit('changeModal', emitParams)
      }
    }
  }
}
</script>

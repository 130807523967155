export default `TERMS AND CONDITIONS AGREEMENT ON INFORMATION USAGE BY ROBUXPIER.СС COMPANY

THIS AGREEMENT IS INTENDED TO REGULATE THE RELATIONS BETWEEN THE USER AND THE RIGHT HOLDER OF THE INTERNET PORTAL

<b>CONTENT</b>:

1. USED TERMS.

2. SUBJECT OF THE AGREEMENT.

3. PROCEDURE FOR THE ENTERING INTO FORCE OF THE AGREEMENT.

4. RIGHTS AND OBLIGATIONS OF THE PARTIES.

4.1 USER RIGHTS.

4.2 USER RESPONSIBILITIES.

4.3 THE USER IS NOT ENTITLED.

4.4 RIGHTS OF THE OPERATOR.

4.5 OPERATOR'S RESPONSIBILITIES.

4.6 LIMITATION OF OPERATOR'S RESPONSIBILITIES.

4.7 THE OPERATOR DOES NOT GUARANTEE.

5. PRIVACY AND SECURITY.

6. ADDITIONAL PAID SERVICES AND PAYMENT PROCEDURE.

7. REFUND OF FUNDS

8. DISCLAIMER OF WARRANTIES.

9. ADDITIONAL PROVISIONS.

10. CONTACT INFORMATION.

<b>1. USED TERMS</b>

<b>«OPERATOR»</b> - RIGHT HOLDER OF THE PORTAL HTTPS://ROBUXPIER.СС, IS A PARTY TO THE USER AGREEMENT. THE OPERATOR CARRIES OUT ADMINISTRATION AND MAINTENANCE, GIVES USERS ACCESS TO THE PORTAL, SERVICES (ALSO PAID SERVICES), UNDER THE TERMS OF THIS AGREEMENT.

<b>«USER»</b> - AN INDIVIDUAL VISITING THE PORTAL OR TAKING PART IN THE PROJECTS OF THE PORTAL HTTPS://ROBUXPIER.СС

<b>USER</b>, AS WELL AS THE OPERATOR, IS A PARTY TO THE AGREEMENT.

<b>«PORTAL»</b> - SPECIAL HARDWARE AND SOFTWARE SYSTEMS PLACED ON THE OPERATOR'S RESOURCES. USERS ACCESS TO THE PORTAL IS CARRIED OUT ONLY BY THE OPERATOR. ALL RIGHTS TO USE THIS PORTAL BELONG TO THE OPERATOR ONLY.

<b>«WEBSITE»</b> — WEBSITE LOCATED ON THE INTERNET AT HTTPS://ROBUXPIER.СС

<b>«SERVICES»</b> — GIVING ACCESS TO THE PORTAL TO USERS, USE OF OPPORTUNITIES AND SERVICES, PARTICIPATION IN PROJECTS ON THE TERMS AND CONDITIONS DEFINED BY THE AGREEMENTS. SERVICES OF THE OPERATOR ARE PROVIDED ON A FREE BASIS. EXCEPTIONS ARE ONLY FOR ADDITIONAL PAID SERVICES. ALL SERVICES ARE PROVIDED BY THE OPERATOR ONLY WITHIN THE PORTAL, I.E. DURING ITS USE BY THE USER.

<b>«ADDITIONAL PAID SERVICES»</b> – PROVIDING THE USER WITH ADDITIONAL SPECIAL FEATURES OF THE PORTAL FOR A PAYMENT. THESE SERVICES ARE NOT COMPULSORY AND ARE PROVIDED ON OPTIONAL REQUEST OF THE USER. PAID SERVICES ARE ALSO PROVIDED BY THE OPERATOR EXCLUSIVELY INSIDE THE PORTAL.

<b>2. SUBJECT OF THE AGREEMENT</b>

2.1. PROVISION BY THE OPERATOR OF ACCESS TO THE PORTAL (AMENITIES, SERVICES, PAID SERVICES) TO UNLIMITED CIRCLE OF PERSONS, UNDER THE TERMS OF THIS AGREEMENT.

2.2. THE USER ACKNOWLEDGES THAT THE MAIN PURPOSE OF THE PROJECTS OF THE PORTAL HTTPS://ROBUXPIER.СС IS TO ORGANIZE LEISURE AND ENTERTAINMENT BY THE OPERATOR, IN NO WAY RELATED TO GAMBLING.

<b>3. PROCEDURE FOR AGREEMENT ENTERING INTO FORCE</b>

3.1. SINCE THE ACCEPTANCE OF THIS AGREEMENT, THE USER IS OBLIGED AND PERFORMS THE OBLIGATIONS PROVIDED IN THE CONTENT OF THIS AGREEMENT.

3.2. THE USER ACCEPTS AND AGREES TO THE TERMS OF THIS AGREEMENT BY REGISTRATION ON THE WEBSITE: HTTPS://ROBUXPIER.СС THE FACT OF ACCEPTANCE MEANS THE FULL AND UNCONDITIONAL CONSENT OF THE USER WITH ALL THE TERMS AND APPENDICES OF THIS AGREEMENT.

3.3. IF THE USER, FOR ANY REASON, DOES NOT AGREE WITH THE TERMS OR APPENDICES OF THIS AGREEMENT, HE IS OBLIGED TO TERMINATE FURTHER USE OF THE WEBSITE.

3.4. WEBSITE USAGE IS POSSIBLE ONLY AFTER ACCEPTANCE OF THIS AGREEMENT BY THE USER.

3.5. BY ACCEPTING THIS AGREEMENT, THE USER CONFIRMS ITS CAPABILITY AND THE RIGHT TO ENTER THIS AGREEMENT. THE OPERATOR IS NOT OBLIGED TO CHECK THE DATA SPECIFIED BY THE USER AT REGISTRATION.

3.6. IF USING THE INFORMATION AND ENTERTAINING PORTAL HTTPS:/ROBUXPIER.СС REQUIRES CREATION OF ACCOUNT (HEREINAFTER “ACCOUNT”) OR PARCITIPATION IN PORTAL PROJECTS, THEN THE USER MUST COMPLETE THE REGISTRATION PROCESS BY PROVIDING THE OPERATOR WITH RELEVANT, COMPLETE AND ACCURATE INFORMATION (INCLUDING EMAIL ADDRESSES) IN THE APPROPRIATE FORM. IN CASE OF POSTING MESSAGES ABOUT PARTICIPATION IN PARTNER PROGRAMS ON THE PORTAL HTTPS://ROBUXPIER.СС, THE REGISTERED USER HAS THE RIGHT TO PARTICIPATE IN COMPETITIONS, ROLLS, COMPETITIONS ON THE WEBSITE OF PARTNERS HTTPS://ROBUXPIER.СС.

<b>4. RIGHTS AND OBLIGATIONS OF THE PARTIES</b>

4.1. USER RIGHTS

UNDER THIS AGREEMENT, THE USER IS ENTITLED TO:

USE THE PORTAL FOR PERSONAL, NON-COMMERCIAL PURPOSES ONLY.

USE ALL SERVICES, RESOURCES (INCLUDING ADDITIONAL PAID SERVICES) PROVIDED BY THE OPERATOR.

IF NECESSARY, USE THE TECHNICAL SUPPORT OF THE WEBSITES, CONTACT THE OPERATOR ON EMERGING QUESTIONS, VIA THE CONTACT INFORMATION OR FEEDBACK FORM.

USE THE WEBSITE FOR FREE (EXCLUDING THE PROVISION OF ADDITIONAL PAID SERVICES) AND PARTICIPATE IN PORTAL PROJECTS.

4.2. USER RESPONSIBILITIES

BY COMPLYING WITH THE TERMS OF THIS AGREEMENT, THE USER IS OBLIGED TO:

INDICATE RELIABLE INFORMATION WHEN REGISTERING ON THE WEBSITE.

TO USE ALL NECESSARY MEASURES TO ENSURE THE EFFECTIVE SECURITY OF THE PERSONAL ACCOUNT. NOT TO PROVIDING ACCESS TO THIRD PARTIES.

IF NECESSARY, AT THE OPERATOR'S REQUEST, PROVIDE CONFIRMATION OF YOUR PERSONAL DATA SPECIFIED ON THE WEBSITE WHILE REGISTRATION.

FOLLOW THE INSTRUCTIONS OF THE OPERATOR WITHIN THIS PORTAL.

NOT VIOLATE THE OPERATOR'S COPYRIGHT AND INTELLECTUAL PROPERTY RIGHTS SPECIFIED ON THE PORTAL.

COMPLY WITH THE TERMS OF THIS AGREEMENT WITHOUT LIMITATION.

USE THE PORTAL ONLY FOR ENTERTAINMENT PURPOSES WITHOUT ANY BENEFITS FROM THE PORTAL.

4.3. THE USER IS NOT OBLIGED

USING THE OPERATOR PORTAL, THE USER IS NOT OBLIGED TO:

USE ERRORS (BUGS) OF THE WEBSITE AND SERVICES OF THE PORTAL, IN UNAUTHORIZED WAY TO GET ACCESS TO THE COMMON DATABASE, COMPUTER SYSTEM, TO CHANGE THE PROGRAM CODE. THE USER IS PROHIBITED TO USE MALICIOUS PROGRAMS THAT CAN HARM THE PORTAL, AS WELL AS SPECIAL SOFTWARE THAT GIVES THEM SUPERIORITY FROM OTHER USERS. IF SUCH AND SIMILAR VIOLATIONS ARE REVEALED, THE OPERATOR MAY APPLY PENALTY TO THE USER, INCLUDING ACCESS TO THE WEBSITES AND ACCOUNT DELETION.

LIMIT ACCESS TO THE WEBSITES OF OTHER USERS.

ENGAGE IN FRAUD AND OTHER ILLEGAL ACTIONS.

ADVERTISE ANYTHING NOT RELATED TO THE PORTAL WITHOUT THE WRITTEN PERMISSION OF THE OPERATOR.

USE ABNORMAL VOCABULARY, EXPRESS THREATS TO THE OPERATOR OR OTHER USERS, SHARE MATERIALS THAT PROMOTE VIOLENCE, RACIAL HATRED, REJECTION OF RELIGIOUS BELIEFS, PORNOGRAPHIC CONTENT, DRUG ADVERTISING, AND ACTIONS CALL FOR VIOLENT OVERTHROW OF GOVERNMENT. .

CARRY OUT ANTI-ADVERTISING OF THE PORTAL, INCLUDING OUTSIDE OF THE PORTAL.

THE USER AGREES THAT HIS RIGHTS AND RESPONSIBILITIES CAN BE CHANGED/ADDED BY THE OPERATOR, OF WHICH HE IS NOTIFIED BY THE CONTACT DATA SPECIFIED AT REGISTRATION (OR ON THE WEBSITE)

4.4. OPERATOR RIGHTS

THIS AGREEMENT PROVIDES THE OPERATOR THE FOLLOWING RIGHTS:

AT ANY TIME, AT ITS SOLE DISCRETION, UNILATERALLY WITHOUT PRIOR NOTICE TO USERS, EXPAND, CHANGE, TERMINATE, LIMIT THE PROVISION OF THE SERVICES, AS WELL AS ADDITIONAL PAID SERVICES.

TO MANAGE ALL PROCESSES ON THE PORTALS ONLY AT YOUR OWN DISCRETION. SUSPEND, CHANGE THE STROKE OF ANY PROCESSES WITHOUT NOTIFYING THE USER IN PRIORITY.

APPLY SANCTIONS TO THE USER IN CASE OF VIOLATIONS OF THIS AGREEMENT.

DELETE/CHANGE USER INFORMATION PLACED ON PORTALS.

TRACK, SAVE IDENTIFICATION AND STATISTICAL INFORMATION ABOUT THE USER.

SEND TO USERS TECHNICAL, ADVERTISING AND OTHER INFORMATION REGARDING THE PORTAL, SERVICES AND PAID SERVICES.

INFORM, WARN, MAKE REMARKS, AND NOTIFY THE USER IN THE EVENT OF NON-COMPLIANCE/VIOLATION OF THE TERMS OF THIS AGREEMENT. ALL INSTRUCTIONS OF THE OPERATOR MUST BE STRICTLY ADHERED TO.

TAKE LEGAL ACTION TO PROTECT YOUR INTELLECTUAL, COPYRIGHT PROPERTY.

MODIFY, CHANGE, AND SUPPLEMENT THE PORTAL AT PERSONAL DISCRETION WITHOUT WARNING THE USER IN ADVANCE.

INACTION BY THE OPERATOR ON VIOLATIONS OF THIS AGREEMENT BY THE USER DOES NOT EXCLUDE THE USE OF PENALTY LATER.

4.5. OPERATOR'S RESPONSIBILITIES

AS A PARTY TO THE USER AGREEMENT, THE OPERATOR IS OBLIGED TO:

ENSURE THE OPPORTUNITY FOR THE USER TO RECEIVE THE SERVICES OF THE OPERATOR WITHIN THIS PORTAL (INCLUDING THE RECEIVING OF ADDITIONAL PAID SERVICES).

ANSWER THE QUESTIONS OF USERS, IN CASE OF DISPUTABLE SITUATIONS, TAKE ALL MEASURES TO THEIR SETTLEMENT.

4.6. LIMITATION OF OPERATOR'S LIABILITY

UNDER THIS SECTION, THE OPERATOR IS NOT RESPONSIBLE FOR:

ANY DAMAGE CAUSED OR WHICH MAY BE CAUSED TO THE PERSONAL DATA AND COMPUTER OF THE USER IN CONNECTION WITH THE USE OF THE PORTAL AND THE WEBSITE.

DAMAGES (DIRECT/INDIRECT) CAUSED TO THE USER BECAUSE OF USAGE OR UNAVAILABILITY OF THE PORTAL (INABILITY TO USE THEM), THE BEHAVIOR OF THIRD PARTIES ON THE PORTAL, AS WELL AS OTHER PARTICIPANTS OF THE PORTAL PROJECTS, AND UNAUTHORIZED ACCESS TO THE PERSON.

STATEMENTS, DISTRIBUTED INFORMATION, APPLICATIONS OF THE USER AND OTHER ILLEGAL ACTIONS CARRIED OUT BY THEM ON THE PORTAL AND OUTSIDE.

THE INFORMATION SPECIFIED BY THE USER DURING REGISTRATION, THE LOST POSSIBILITY OF ACCESS TO THE PORTAL (LOGIN, PASSWORD, ETC.).

LOSS BY THE USER OF ACQUIRED VIRTUAL VALUES AS A RESULT OF THE PROVISION OF SERVICES AND ADDITIONAL PAID SERVICES BY THE OPERATOR.

PAYMENT BY THE USER FOR ADDITIONAL PAID SERVICES AND RELATED COSTS.

CONTINUOUS OPERATION OF THE PORTAL.

USER OPPORTUNITIES RELATED TO THE INTERNET OUTPUT, DATA TRANSFER RATE.

4.7. THE OPERATOR DOES NOT GUARANTEE:

UNINTERRUPTED, RELIABLE, ERROR-FREE OPERATION OF THE PORTAL, ACCESS TO ITS RESOURCES, SERVICES, INCLUDING ADDITIONAL PAID SERVICES.

THE FACT THAT THE PORTAL WILL FULLY SATISFY THE REQUIREMENTS AND SUBMISSIONS OF THE USER.

CONFORMITY OF THE QUALITY OF THE PROVIDED SERVICES (ADDITIONAL PAID SERVICES) WITH THE USER'S EXPECTATIONS.

THE OPERATOR IS NOT OBLIGED AT THE USER'S REQUEST TO SUBMIT DOCUMENTS AND OTHER EVIDENCE EVIDENCE OF A BREACH (BY THE USER) OF THIS AGREEMENT AND THE APPLICATION OF PENALTY/DISCIPLINARY SANCTIONS AGAINST ITSELF.

THE USER USES THE PORTAL AND THE OPERATOR'S SITE SOLELY AT ITS OWN RISK, IN GOODWILL, WITHOUT COERCION. THE USER UNDERSTANDS THE POSSIBLE RISKS ASSOCIATED WITH THE USE OF THE OPERATOR'S RESOURCES AND HAS NO MATERIAL CLAIMS AGAINST THE OPERATOR.

<b>5. PRIVACY AND SECURITY</b>

5.1. CONFIDENTIAL INFORMATION IMPLIES INFORMATION OBTAINED BY THE OPERATOR IN THE PROCESS OF REGISTRATION OF A USER ON THE WEBSITE, AND ALSO DURING VISITING THE WEBSITES/PORTAL AND PARTICIPATION IN PORTAL EVENTS.

5.2. CONFIDENTIAL INFORMATION IS NOT SUBJECT TO DISCLOSURE AND TRANSFER TO THIRD PARTIES.

5.3. PERSONAL DATA MAY BE TRANSFERRED BY THE OPERATOR ONLY IN CASE OF:

FORMAL REQUEST BY LAW ENFORCEMENT AUTHORITIES (VIOLATION OF LOCAL AND INTERNATIONAL LAWS).

USER'S PERSONAL WILL.

IMPOSSIBILITY TO USE SERVICES AND ADDITIONAL PAID SERVICES ON PORTALS (WHICH THE USER IS WARNED OF IN ADVANCE).

VIOLATIONS OF THE ITEMS OF THIS AGREEMENT (AT THE OPTION OF THE OPERATOR).

5.4. THE OPERATOR ENSURES THE SECURITY OF THE USER'S PERSONAL DATA USING SPECIAL SOFTWARE. IN THE EVENT OF UNAUTHORIZED ACCESS TO THE PORTAL/SITE OF A THIRD PARTY, THE SECURITY OF PERSONAL DATA IS NOT GUARANTEED.

<b>6. ADDITIONAL PAID SERVICES AND PAYMENT PROCEDURE</b>

6.1. AT THE OPTION OF THE USER, THE OPERATOR PROVIDES WITH AN ADDITIONAL PAID SERVICE. IT ALLOWS YOU TO USE THE EXTENDED FEATURES OF THE PORTAL — RECEIVING GAME CURRENCY ON YOUR ACCOUNT BALANCE.

6.2. ADDITIONAL PAID SERVICES ARE NOT A MANDATORY CONDITION FOR USING THE PORTAL AND PARTICIPATION IN THE PROJECTS OF THE PORTAL.

6.3. FROM THE MOMENT OF DEBITING OF THE USER'S ACCOUNT FUNDS BY THE PORTAL OPERATOR, THE ADDITIONAL PROPER QUALITY PAID SERVICE IS CONSIDERED TO BE PROVIDED IN A FULL.

6.4. AFTER THE ADDITIONAL PAID SERVICE IS PROVIDED, THE MONEY SPENT ON ITS PURCHASE IS NON-REFUNDABLE.

6.5. THE USER AGREES THAT THE OPERATOR HAS THE RIGHT TO STORE PERSONAL INFORMATION OBTAINED WHEN PURCHASING ADDITIONAL PAID SERVICES.

6.6. THE LIST OF ADDITIONAL PAID SERVICES AND THEIR COST ARE PUBLISHED ONLY BY THE OPERATOR ON THE SITE/PORTAL. ANY INFORMATION AND OFFERS TO PURCHASE THESE SERVICES ON THIRD PARTY RESOURCES ARE CONSIDERED A FRAUD FOR WHICH THE OPERATOR IS NOT RESPONSIBLE.

6.7. WAYS AND POSSIBILITY TO PURCHASE ADDITIONAL PAID SERVICES IS EXPLAINED BY THE OPERATOR ON THE WEBSITE.

6.8. THE OPERATOR DOES NOT GIVE EXPLANATIONS REGARDING WORK WITH PAYMENT SYSTEMS USED BY USER TO PURCHASE ADDITIONAL PAID SERVICES, AND IS NOT RESPONSIBLE FOR THEIR CORRECT WORK.

6.9. IN CASE OF A TECHNICAL FAILURE OF THE WEBSITES OR INTENTIONAL ACTIONS OF THE USER, AS WELL AS IN OTHER CASES WHEN ADDITIONAL PAID SERVICES HAVE BEEN PROVIDED WITHOUT A FULL/PARTIAL DEBITING OF FUNDS FROM THE USER'S ACCOUNT, THE USER IS OBLIGED TO REPORT THIS TO THE OPERATOR. AFTER THIS, THE USER IS OBLIGED TO PAY OFF THE ARISING DEBTS.

6.10. THE OPERATOR DOES NOT REFUND THE MONEY TO THE USER FOR UNUSED (PARTIALLY USED) ADDITIONAL PAID SERVICES.

6.11. THE USER, AT ITS OWN EXPENSE, INDEPENDENTLY BEARS ALL FINANCIAL EXPENSES RELATED TO THE PURCHASE (TRANSFER OF MONEY) OF ADDITIONAL PAID SERVICES. THIS SECTION INCLUDES COMMISSIONS, FEES, AND OTHER EXPENSES.

6.12. THE USER GUARANTEE TO THE OPERATOR THAT HAS ALL LEGAL POWERS AND RIGHTS TO CONCLUDING THIS AGREEMENT IN PART OF ADDITIONAL PAID SERVICES.

6.13. IN CASE OF PURCHASING ADDITIONAL PAID SERVICES BY A USER UNDER 18 YEARS OLD, THEY SHOULD PRELIMINARY OBTAIN CONSENT TO CARRY OUT A FINANCIAL OPERATION FROM LEGAL REPRESENTATIVES. THE FACT OF PURCHASING ADDITIONAL PAID SERVICES IS A CONFIRMATION OF OBTAINING SUCH CONSENT FROM THE LEGAL REPRESENTATIVE. IF NECESSARY, THE OPERATOR IS ENTITLED TO REQUEST WRITTEN CONFIRMATION OF THE CONSENT AND PROVISION OF PASSPORT DATA TO DETERMINE THE REAL AGE OF THE USER.

6.14. RESPONSIBILITY FOR THE PURCHASE OF ADDITIONAL PAID SERVICES SHALL BE FULLY RESPONSIBLE ON THE USER AND HIS LEGAL REPRESENTATIVESTHE USER AND HIS LEGAL REPRESENTATIVES ARE FULLY RESPONSIBLE FOR THE PURCHASE OF ADDITIONAL PAID SERVICES .

6.15. DISPUTES ABOUT RESPONSIBILITY FOR THE PURCHASE OF ADDITIONAL PAID SERVICES WITH THE PORTAL OPERATOR ARE UNACCEPTABLE.

6.16. RECEIVING ADDITIONAL PAID SERVICES BY THE USER IS POSSIBLE ONLY AFTER THE FULL PAYMENT OF THEIR COST.

6.17. PROCEDURE OF PAYMENT

6.17.1. PAYMENT CARDS ACCEPTED: VISA, MASTERCARD, MAESTRO, MIR. TO PAY FOR THE GOODS WITH A BANK CARD WHEN PLACING THE ORDER IN THE INTERNET STORE, THE USER SELECTS THE METHOD OF PAYMENT: WITH A BANK CARD. WHEN PAYING AN ORDER WITH A BANK CARD, THE PAYMENT IS PROCESSED ON THE AUTHORIZATION PAGE OF THE BANK, WHERE THE USER IS NECESSARY TO ENTER THE DETAILS OF HIS BANK CARD 1) CARD TYPE 2) CARD NUMBER, 3) CARD EXPIRY DATE 4) NAME OF THE CARD HOLDER (IN LATIN LETTERS, EXACTLY AS STATED ON THE CARD) 5) CVC2/CVV2 CODE

6.17.2. IF THE USER'S CARD IS CONNECTED TO THE 3D-SECURE SERVICE, IT WILL BE AUTOMATICALLY REDIRECTED TO THE PAGE OF THE BANK ISSUING THE CARD TO PASS THE AUTHENTICATION PROCEDURE. INFORMATION ABOUT THE RULES AND METHODS OF ADDITIONAL IDENTIFICATION CAN BE SPECIFIED IN THE BANK ISSUING THE BANK CARD TO THE USER.

<b>7. REFUND OF FUNDS</b>

7.1. THE USER HAS THE RIGHT TO REQUEST A REFUND, PROVIDED THAT THEY HAVE NOT BEEN SPENT AFTER REPLENISHING THE BALANCE
7.2. ALL PURCHASES AND ACTIVITIES MADE THROUGH THE SERVICE ON THE SITE ARE FINAL AND NON-REFUNDABLE, THE MONEY SPENT IS NOT REFUNDABLE AT ALL.

<b>8. DISCLAIMER OF WARRANTIES</b>

ALL SERVICES ON THE PORTAL ARE PROVIDED TO USERS ACCORDING TO THE ESTABLISHED CONCEPT "AS IT IS". THE PORTAL DISCLAIMS WARRANTIES REGARDING THE SERVICES OR VIRTUAL VALUES.

THE OPERATOR HEREBY WARNS AND REMINDS THAT EXCESSIVE USE OF A PC, INCLUDING PC GAMES, MAY BE HARMFUL TO THE USER'S HEALTH. THE USER OR ITS LEGAL REPRESENTATIVES AGREE TO CONTROL HEALTH AND NOT TO USE THE SERVICES/LIMIT THEIR USE IN THE PRESENCE OF ANY CONTRAINDICATIONS.

THE OPERATOR IS NOT RESPONSIBLE FOR ANY INDIRECT, RANDOM, OR OTHER LOSSES (INCLUDING, LOST BENEFITS AMONG OTHER THINGS) AS A RESULT OF GAME SERVICES, PORTAL WEBSITES AND/OR WEBSITE MATERIALS, THIRD PARTIES ASSOCIATED WITH THE SERVICES AND/OR MATERIALS ON THE PORTAL OF THE OPERATOR WEBSITE, INCLUDING FRAUD AND/OR NEGLIGENT ACTIONS OF THIR PARTIES.

<b>9. ADDITIONAL PROVISIONS</b>

9.1. ЕIF THE USER DOES NOT HAVE THE RIGHT TO USE THE PORTAL ACCORDING TO THE LEGISLATION OF ITS COUNTRY OR THERE ARE OTHER LIMITATIONS (AGE PERMISSION AND OTHER), THE USER IS OBLIGED WITHOUT WARNING TO REFUSE TO USE THE PORTAL, AS WELL AS THEIR SERVICES. THE USER ASSUMES ENTIRE RESPONSIBILITY FOR THE USE OF THE PORTAL IN ITS COUNTRY, BASED ON LOCAL LAWS AND CONSIDERING INTERNATIONAL LAWS.

9.2. THE INVALIDITY OF ONE/SEVERAL ITEMS/SECTIONS OF THE USER AGREEMENT DOES NOT INCLUDE ITS VOID IN GENERAL. IN THIS CASE, THE PARTIES SHOULD PERFORM THE OBLIGATIONS ASSUMED UNDER THE OTHER ITEMS/SECTIONS OF THE AGREEMENT.

9.3. DISPUTES ARISING BETWEEN THE PARTIES ARE SUBJECT TO INITIAL ADJUSTMENT BY CORRESPONDENCE BY THE OPERATOR AND THE USER. IF THE DISPUTES SETTLEMENT WITH MEDIATION IS INEFFICIENT, THEY WILL BE RESOLVED IN ACCORDANCE WITH THE LEGISLATION OF THE REPUBLIC OF ESTONIA.

9.4. THIS AGREEMENT MAY BE CHANGED OR ADDED BY THE OPERATOR WITHOUT PRIOR NOTICE TO THE USER. ANY CHANGES WILL COME INTO FORCE IMMEDIATELY AFTER THE AMENDED VERSION OF THE AGREEMENT IS PUBLISHED ON THE WEBSITE. TO AVOID DISPUTE ISSUES, THE USER IS OBLIGED TO INDEPENDENTLY CHECK THE AGREEMENT TEXT ON THE WEBSITE WHERE IT IS IN FREE ACCESS. IN CASE THE AGREEMENT TEXT VERIFICATION IS NOT PERFORMED BY THE USER, THIS FACT CAN NOT BE A BASIS FOR REFUSAL TO PERFORM THE OBLIGATIONS ASSURED. AN AMENDED VERSION OF THE AGREEMENT AFTER PUBLISHING ON THE SITE HAS A SIMILAR LEGAL VALIDITY TO THE ORIGINAL TEXT.

10. CONTACT INFORMATION

CONTACT EMAIL: EPIK582@GMAIL.COM
`.split(new RegExp(/\s+\r?\n/))

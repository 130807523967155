import policy from '../policy/pt'
import terms from '../terms/pt'

export default {
  navigation: {
    title: 'NAVEGAÇÃO',
    home: 'INÍCIO',
    buyRobux: 'COMPRAR ROBOKS',
    lk: 'ÁREA PESSOAL',
    vk: 'Grupo VK',
    discord: 'Discord',
    telegram: 'Telegram',
    instagram: 'Instagram',
    tiktok: 'Tik Tok',
    dropdown: {
      history: 'História',
      changePassword: 'Alterar senha',
      refill: 'Recarregar',
      logout: 'Sair'
    }
  },
  general: {
    warning: 'Atenção!',
    error: 'Erro',
    gotIt: 'Entendi',
    confirm: 'CONFIRMAÇÃO',
    or: 'ou',
    link: 'link',
    transfer: 'transferência',
    policy: 'POLÍTICA PRIVACIDADE',
    terms: 'TERMOS DE SERVIÇO',

    errors: {
      internal: 'Erro interno, tente novamente mais tarde',
      server: 'Erro do servidor'
    }
  },
  actions: {
    continue: 'Continuar',
    submit: 'CONFIRMAR',
    buyRobux: 'COMPRAR ROBOKS',
    openDiscord: 'IR PARA DISCORD',
    openInstruction: 'IR PARA AS INSTRUÇÕES',
    createAccount: 'Criar conta',
    login: 'ENTRAR NA CONTA',
    loginVK: 'Entrar com Vkontakte',
    signin: 'Entrar',
    enterPassword: 'Digite-o',
    skip: 'Ignorar',
    buy: 'Obter',
    buyOrder: 'Comprar',
    tryAgain: 'Repetir novamente',
    create: 'Create',
    ready: 'Ready',
    edit: 'Edit'
  },
  payment: {
    refill: 'Depósito de fundos',
    total: 'Total',
    toPay: 'Pagamento',
    pay: 'PAGAR',
    makeOrder: 'Pagar pedido',
    otherPaymentMethods: 'Outros métodos de pagamento',
    enterSum: 'Insira o valor do depósito',
    commission: 'Comissão',
    notEnough: 'Você não tem fundos suficientes para pagar!',
    problems: 'Em caso de problemas, escreva para o nosso {support}',
    support: 'serviço suporte',
    selectPaymentMethod: 'ESCOLHA UM MÉTODO DE PAGAMENTO'
  },
  order: {
    status: 'STATUS DO PEDIDO',
    wait: 'POR FAVOR, AGUARDE a conclusão do pedido',
    waitTime: 'RESTANTE',
    code: 'O código deve ter 6 dígitos',
    mailCode: 'Digite o código do E-mail',
    makeNewOrder: 'Faça mais um pedido',
    table: {
      nickname: 'NICK DO JOGO',
      count: 'QUANTIDADE R$',
      total: 'TOTAL, RUB'
    },
    descriptions: {
      wait: 'Aguarde realizar uma ordem',
      notEnoughFunds: 'Erro. O site não tem recursos suficientes!',
      systemError: 'Erro do sistema!',
      checkVip: 'Verifique as configurações VIP ROBLOX!',
      connectionError: 'Erro de comunicação com ROBLOX!',
      success: 'Pedido concluído com sucesso!',
      checkGamepass: 'Verifique as configurações do Gamepass!'
    }
  },
  auth: {
    authorization: 'Autorização',
    registration: 'Registo',
    recovery: 'RECUPERAÇÃO',
    change: 'ALTERAR SENHA',

    inputs: {
      email: 'Digite seu e-mail',
      login: 'Digite seu login',
      loginOrEmail: 'Digite seu login ou e-mail',
      currentPassword: 'Senha atual',
      newPassword: 'Nova senha',
      repeatNewPassword: 'Repetir nova senha',
      password: 'Digite sua senha',
      passwordSubmit: 'Confirmar senha'
    },

    errors: {
      email: 'E-mail inválido!',
      incorrectPassword: 'Senha inválida!',
      empty: 'Campo vazio',
      nicknameIsTooSmall: 'Você deve fornecer um nome com pelo menos 4 caracteres',
      inactiveEmail: 'Email inválido',
      passwordIsTooSmall: 'Sua senha deve ter pelo menos 6 caracteres',
      passwordNotMatch: 'Senhas não correspondem',
      oneUppercase: 'Sua senha deve ter pelo menos uma letra maiúscula',
      userExist: 'O usuário com esses dados já existe',
      checkEmail: 'Verifique se seu e-mail/nome de usuário está correto',
      checkPassword: 'Verifique se sua senha está correta',
      changePasswordIncorrect: 'A senha deve ter de 6 a 18 caracteres e ser composta pelas letras “A-z”!'
    },

    welcome: {
      title: 'BEM-VINDO',
      content: 'REGISTE - SE NO NOSSO SITE E COMECE A COMPRAR ROBUX DE FORMA SEGURA, RÁPIDA E SEGURA'
    },

    forget: 'Esqueceu sua senha?',
    remember: 'Lembra-se da senha?',
    agreement: 'Concordo com {policy}',
    privacyPolicy: 'Política privacidade',
    haveAccount: 'Já tem uma conta?',
    codeSent: 'Um código de recuperação de senha foi enviado para o e-mail {email}!',
    enterCode: 'Digite o código de 6 dígitos:',
    requestCode: 'Solicitar novamente',

    changePasswordSuccess: {
      title: 'Óptimo!',
      content: 'Você alterou sua senha com sucesso! Certifique-se de lembrar...'
    }
  },
  slider: {
    slide1: {
      title: '#1 COMPRE ROBUX COM SEGURANÇA!',
      content: 'Toda a moeda do jogo é obtida legalmente de desenvolvedores de jogos'
    },
    slide2: {
      title: '#2 OS MELHORES PREÇOS!',
      content: 'Somente em nossa loja você pode comprar robuxs com os preços mais baixos e garantidamente obtê-los!'
    },
    slide3: {
      title: '#3 OS CLIENTES CONFIAM EM NÓS!',
      content: 'Nossa loja existe há três anos e durante esse tempo provou-se!'
    }
  },
  faq: {
    title: 'QUER COMPRAR ROBUX?',
    content: 'PREÇOS FAVORÁVEIS, ENTREGA RÁPIDA!',
    items: {
      item1: {
        index: '01',
        title: 'FAÇA UM PEDIDO',
        content: 'Escolha o número de Robux para comprar e sua conta Roblox'
      },
      item2: {
        index: '02',
        title: 'Pague',
        content: 'Pague seu pedido de qualquer maneira conveniente'
      },
      item3: {
        index: '03',
        title: 'RECEBA ROBUX',
        content: 'Receba robux em sua conta de Jogador dentro de 5 dias!'
      }
    }
  },
  help: {
    title: 'Está com dificuldades em comprar robux?',
    text1: 'Nosso suporte com certeza irá te ajudar com eles no servidor no discord ou em mensagens pessoais do Instagram!',
    text2: 'Você também pode usar o manual de instruções'
  },
  footer: {
    privacy: 'POLÍTICA PRIVACIDADE',
    terms: 'TERMOS DE SERVIÇO',
    contacts: 'INFORMAÇÕES CONTATO'
  },
  methods: {
    title: 'QUAL MÉTODO DE COMPRA VOCÊ DESEJA USAR?',
    hintError: {
      title: 'Erro!',
      content: 'Você já tem o Roblox Premium!'
    },
    method: 'Método',
    robux: 'Robux',
    premium: 'Premium',
    transfer: 'Transferências',
    logpass: 'log+pass',
    available: 'Disponível',
    notAvailable: 'Não disponível'
  },
  cookie: {
    whatIsCookie: 'O que é um COOKIE?',
    checkInstruction: 'LEIA AS INSTRUÇÕES DE COOKIES',
    enter: 'Insira Cookie',
    description: {
      part1: 'Os cookies em si não são perigosos — são arquivos de texto comuns. Eles não podem executar processos no computador ou interagir com o sistema operacional em geral. No entanto, eles podem ser interceptados ou roubados para rastrear suas atividades anteriores on-line ou fazer login em suas contas sem autorização.',
      part2: 'Normalmente, as informações que são gravadas em um cookie são criptografadas antes de serem enviadas, e os próprios cookies são transmitidos através do protocolo HTTPS. Isso ajuda a proteger os dados do usuário, mas o desenvolvedor do site é responsável pela implementação da criptografia e pelo envio seguro. Os visitantes só podem esperar que tudo esteja configurado corretamente. Por sua vez, o Usuário só pode impedir o navegador de usar Cookies ou limpá-los de tempos em tempos.'
    }
  },
  transfer: {
    robuxDelivery: 'Robux serão creditados em 5 dias (120 horas)',
    available: 'Em estoque:',
    robuxCount: 'Número de robux',
    minumum: 'Mínimo de {min} R$',
    nickname: 'Nome de jogo',
    notFound: 'Nada encontrado',
    checkPlaces: 'POR FAVOR, VERIFIQUE SE VOCÊ TEM PELO MENOS 1 PLACE QUE É PÚBLICO',
    vipPrice: 'Definir o preço do servidor VIP em {price}',
    changePrice: 'Alterar preço',
    orderSuccess: 'Você pode acompanhar o recebimento de robux em sua conta {here}',
    orderError: 'Os fundos foram devolvidos ao saldo da conta ROBUXPIER. Tente novamente :)',
    here: 'aqui',
    gamepass: {
      checkData: 'Check the correctness of the data in Gamepass!',
      creating: 'CREATING',
      settingUp: 'SETUP',
      createGamepass: 'Create a Game-pass, you can use <a href="https://backend-pier.com/luckyteam.jpg" target="_blank">this </a>image',
      checkInstruction: 'View the instructions and set the price <span>{price} R$</span>'
    },
    errors: {
      insufficientBalance: 'Saldo insuficiente',
      notAuthorized: 'Você não está está logado',
      serverError: 'Erro no servidor',
      robuxNotAvailable: 'Número de robux não disponível para compra',

      priceNotNumber: 'O preço deve ser um número',
      robuxMinimum: 'Não se pode pedir menos de {robux} robux.',
      halfBalance: 'Não pode pedir mais de metade do saldo: {balance} robux',
      robuxMustDivide: 'R$ deve ser compartilhado por {orders}. Tente <span class="min">{closestMin}R$</span> ou <span class="max">{closestMax}R$</span>'
    },
    selectYourPlace: 'Escolha o seu'
  },
  logpass: {
    enterNickname: 'DIGITE SEU APELIDO',
    buyRobuxTitle: 'COMPRA robux',
    codes: {
      code_request: 'Por favor, insira o código que você recebeu em seu e-mail',
      order_awaiting: 'Aguarde a execução do pedido',
      code_request_awaiting: 'Aguarde um pedido de código do E-mail',

      password_error: 'Senha inválida no ROBLOX',
      code_error: 'Código de autenticação em duas etapas inválido do E-mail',
      cookie_error: 'Erro. Dados de autenticação inválidos',
      email_timeout_error: 'Você não forneceu o código! Repita o pedido e tente inserir o código o mais rápido possível!',
      error: 'Erro. Tente novamente',
      success: 'Pedido concluído com sucesso',
      waiting: 'Aguarde realizar uma ordem'
    }
  },
  premium: {
    buyPremium: 'COMPRA PREMIUM',
    alreadyExist: 'Você já tem o Roblox Premium!',
    subscribe: {
      title: '{percent} PARA OS ROBUX!',
      subtitle: 'com uma assinatura premium, você terá mais robux'
    },
    getRbx: 'Receba {rbx} na conta',
    buyWithProfit: 'COMPRE COM LUCRO!',
    currencyPerMonth: '{currency} por um mês',
    toPay: 'Total a pagar: ',
    premiumTime: 'Assinatura premium é comprada apenas em {time}',
    oneMonth: '1 mês sem renovação automática',
    enterPromocode: 'Introduza o código promocional',
    ordering: 'FAÇA UM PEDIDO',
    mailCode: 'AGUARDE UM PEDIDO DE CÓDIGO DO E-MAIL',

    correctSum: 'Insira o valor correto',
    minRefillSum: 'O valor do depósito deve ser de pelo menos 5 rublos'
  },
  supportChat: {
    hint: 'Tens uma pergunta?',
    title: 'SUPORTE JOGADORES',
    operator: 'Seu assistente',
    actions: 'Opções de ação',
    anket: 'Apresente-se para entrar em contato com o operador. Você certamente será respondido a perguntas relacionadas ao RobuxPier.',
    name: 'Nome',
    email: 'E-mail',
    vk: 'Link para Vkontakte',
    send: 'Enviar',
    enterMessage: 'Digite sua mensagem...'
  },
  qiwi: {
    details: 'DETALHES DE RECARGA',
    passport: 'Você tem passaporte russo?',
    noPassport: 'Como você não tem um passaporte russo, você não será capaz de passar pela verificação no sistema de pagamento Qiwi.',
    hasPassport: 'Você precisa ser verificado no site da Qiwi para que a Comissão seja menor.',
    noPassportCommission: 'Comissão será de 7,5%',
    hasPassportCommission: 'Comissão será de 3%',
    yes: 'Sim',
    no: 'Não'
  },
  history: {
    nickname: 'Nome de jogo',
    sumRobux: 'Montante, R$',
    sumRub: ' montante ',
    status: 'Status',
    description: 'Descrição',
    timer: 'Timer',

    error: 'Erro',
    wait: 'Espera',
    success: 'Sucesso',
    orderCount: 'Mostrando {order} de {length} entradas'
  },
  policy,
  terms
}

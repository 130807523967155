<template>
  <div class="gamepass-instruction transfer__wrapper">
    <div class="transfer-instruction-gamepass">
      <Loader v-if="loading" />

      <div class="hint" :class="{active: hint}">
        <div class="hint-border">
          <div class="hint__content">
            <p class="hint__content__title">{{ $t('general.warning') }}</p>
            <p class="hint__content__text">{{ $t('transfer.gamepass.checkData') }}</p>
          </div>
        </div>
      </div>

      <div class="transfer-instruction-gamepass__logo">
        <Logo dark />
      </div>

      <div class="transfer-instruction-gamepass__title" v-if="step === 'create'">{{ $t('transfer.gamepass.creating') }} <span class="blue">GAME PASS</span></div>
      <div class="transfer-instruction-gamepass__title" v-else>{{ $t('transfer.gamepass.settingUp') }} <span class="green">GAME PASS</span></div>

      <div class="transfer-instruction-gamepass__subtitle" v-if="step === 'create'" v-html="$t('transfer.gamepass.createGamepass')"></div>
      <div class="transfer-instruction-gamepass__subtitle" v-else v-html="$t('transfer.gamepass.checkInstruction', {price: Math.round(data.totalRobux / 0.7)})"></div>

      <div class="transfer-instruction-gamepass__content">
        <div class="transfer-instruction-gamepass__content__wrapper">
          <div class="transfer-instruction-gamepass__content__wrapper__creating" v-if="step === 'create'">
            <TransferInstructionGamepassCreating :place="data.placeName" />

            <a :href="`https://create.roblox.com/creations/experiences/${data.uid}/passes/create`" target="_blank" class="transfer-instruction-gamepass__content__wrapper__button orange" v-if="!isCreated" @click="createGamepass">
              {{ $t('actions.create') }}
              <img src="@/assets/images/transfer/arrow-right.svg" alt="arrow-right" srcset="">
            </a>

            <div class="transfer-instruction-gamepass__content__wrapper__button ready" v-else @click="submit">
              <img src="@/assets/images/transfer/checkmark.svg" alt="checkmark" srcset="">
              {{ $t('actions.ready') }}
            </div>
          </div>

          <div class="transfer-instruction-gamepass__content__wrapper__edit" v-else>
            <TransferInstructionGamepassEdit :price="data.totalRobux" />

            <a :href="`https://create.roblox.com/creations/experiences/${data.uid}/passes/${gamepassId}/sales`" target="_blank" class="transfer-instruction-gamepass__content__wrapper__button orange" v-if="!isEdited" @click="editGamepass">
              {{ $t('actions.edit') }}
              <img src="@/assets/images/transfer/arrow-right.svg" alt="arrow-right" srcset="">
            </a>

            <div class="transfer-instruction-gamepass__content__wrapper__button ready" v-else @click="submit">
              <img src="@/assets/images/transfer/checkmark.svg" alt="checkmark" srcset="">
              {{ $t('actions.ready') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './transferInstructionGamepass.scss'

import Loader from '@/components/LoaderBar/LoaderBar.vue'
import TransferInstructionGamepassCreating from './transferInstructionGamepassCreating/TransferInstructionGamepassCreating.vue'
import TransferInstructionGamepassEdit from './transferInstructionGamepassEdit/TransferInstructionGamepassEdit.vue'
import Logo from '@/components/Logo/Logo.vue'

export default {
  name: 'TransferInstructionGamepass',
  props: ['data'],
  components: {
    Loader,
    Logo,
    TransferInstructionGamepassCreating,
    TransferInstructionGamepassEdit
  },
  data () {
    return {
      step: 'create',
      isCreated: false,
      isEdited: false,
      loading: true,
      hint: false,
      gamepassId: null
    }
  },
  created () {
    this.loading = true

    this.getGamepasses().then(data => {
      this.loading = false

      if (data.data.length) {
        this.step = 'edit'
        this.gamepassId = data.data[0].id
      } else this.step = 'create'
    })
  },
  methods: {
    getGamepasses () {
      return fetch(`https://transfer.backend-pier.com/get_passes/${this.data.uid}`)
        .then((res) => res.json())
        .catch((err) => console.log(err))
    },
    createGamepass () {
      this.isCreated = true
    },
    editGamepass () {
      this.isEdited = true
    },
    showHint () {
      this.isCreated = false
      this.isEdited = false
      this.hint = true
      setTimeout(() => { this.hint = false }, 4000)
    },
    submit () {
      this.loading = true

      this.getGamepasses().then(data => {
        this.loading = false

        if (!this.isEdited) {
          if (data.data.length) {
            this.gamepassId = data.data[0].id
            this.step = 'edit'
            return
          }

          this.showHint()
          return
        }

        if (data.data.length && data.data[0].price === Math.round(this.data.totalRobux / 0.7)) {
          this.gamepassId = data.data[0].id
          this.$emit('changeModal', {
            modal: 'TransferPayment'
          })
        } else this.showHint()
      })
    }
  }
}
</script>

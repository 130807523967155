<template>
  <div class="payment-gamepass-creating">
    <div class="payment-gamepass-creating__table">
      <div class="payment-gamepass-creating__table__nav">
        <p>Experiences</p>
        <p>Places</p>
        <p>Models</p>
        <p>Decals</p>
        <p>Badges</p>
        <p class="active">Passes</p>
        <p>Audio</p>
        <p>Animations</p>
        <p>Meshes</p>
        <p>User ads</p>
      </div>
      <div class="payment-gamepass-creating__table__passes">
        <div class="payment-gamepass-creating__table__passes__title">Create a Pass</div>
        <div class="payment-gamepass-creating__table__passes__form" v-show="page === 'base'">
          <span>Target Experience: <span class="link">{{ place }}</span> </span>

          <div class="payment-gamepass-creating__table__passes__form__input">
            <div>Find your image:</div>
            <input type="file" readonly>
            <span class="pulse-focus" ref="pulse-focus-file"></span>
          </div>

          <div class="payment-gamepass-creating__table__passes__form__input">
            <div>Pass Name:</div>
            <input type="text" :value="passName" readonly class="payment-gamepass-creating__table__passes__form__input__name">
          </div>

          <div class="payment-gamepass-creating__table__passes__form__input">
            <div>Description:</div>
            <textarea readonly rows="2"></textarea>
          </div>

          <div class="payment-gamepass-creating__table__passes__form__button">
            <span class="pulse-focus" ref="pulse-focus-button"></span>
            Preview
          </div>
        </div>

        <div class="payment-gamepass-creating__table__passes__form" v-show="page === 'verify'">
          <div class="payment-gamepass-creating__table__passes__form__row">
            <div class="payment-gamepass-creating__table__passes__form__row__image">IMAGE HERE</div>
            <div class="payment-gamepass-creating__table__passes__form__row__text">
              <span>Name: {{ image }} </span>
              <span>Target Experience: <span class="link">{{ place }}</span> </span>
              <span>Description:</span>
            </div>
          </div>

          <div class="payment-gamepass-creating__table__passes__form__button verify">
            <span class="pulse-focus" ref="pulse-focus-verify"></span>
            Verify Upload
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './transferInstructionGamepassCreating.scss'

export default {
  name: 'TransferInstructionGamepassCreating',
  props: ['place'],
  data () {
    return {
      image: 'imageName',
      passName: '',
      page: 'base',
      interval: null
    }
  },
  mounted () {
    this.init()

    this.interval = setInterval(() => {
      this.init()
    }, 11000)
  },
  destroyed () {
    clearInterval(this.interval)
  },
  methods: {
    init () {
      this.passName = ''
      const focusFile = this.$refs['pulse-focus-file']
      const focusButton = this.$refs['pulse-focus-button']
      const verifyButton = this.$refs['pulse-focus-verify']
      focusButton.style.display = 'none'
      verifyButton.style.display = 'none'
      focusFile.style.display = 'block'
      this.page = 'base'

      setTimeout(() => {
        this.passName = this.image
        focusFile.style.display = 'none'
        focusButton.style.display = 'block'
      }, 3000)

      setTimeout(() => {
        focusButton.style.display = 'none'
        this.page = 'verify'
      }, 6000)

      setTimeout(() => {
        verifyButton.style.display = 'block'
      }, 7000)

      setTimeout(() => {
        verifyButton.style.display = 'none'
        this.page = 'base'
      }, 11000)
    }
  }
}
</script>

<template>
  <div class="payment-gamepass-editing">
    <div class="payment-gamepass-editing__title">Configure Pass</div>

    <div class="payment-gamepass-editing__inner">
      <div class="payment-gamepass-editing__nav">
        <p>General</p>
        <p class="active">Sales</p>
      </div>
      <div class="payment-gamepass-editing__content">
        <div class="payment-gamepass-editing__content__wrapper">
          <div class="payment-gamepass-editing__content__wrapper__header">
            Item for Sale
            <div class="payment-gamepass-editing__content__wrapper__header__toggle" :class="{'active': checked}">
              <span class="pulse-focus" ref="pulse-focus-checkbox"></span>
              <div class="payment-gamepass-editing__content__wrapper__header__toggle__flip" :class="{'active': checked}"></div>
            </div>
          </div>

          <div class="payment-gamepass-editing__content__wrapper__content" v-show="checked">
            <h1>Price</h1>
            <div class="payment-gamepass-editing__content__wrapper__content__container">
              <div class="payment-gamepass-editing__content__wrapper__content__container__input">
                <span></span>
                <input type="text" :value="priceValue" ref="price-input" readonly>
              </div>
              <div class="payment-gamepass-editing__content__wrapper__content__container__subtext">
                <div>Creator earnings (70%)</div>
                <div class="payment-gamepass-editing__content__wrapper__content__container__subtext__earn">
                  <span></span>
                  {{ Math.round(priceValue * 0.7) }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="payment-gamepass-editing__content__save">
          <span class="pulse-focus" ref="pulse-focus-save"></span>
          Save
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './transferInstructionGamepassEdit.scss'

export default {
  name: 'TransferInstructionGamepassEdit',
  props: ['price'],
  data () {
    return {
      checked: false,
      interval: null,
      priceValue: 100
    }
  },
  mounted () {
    this.init()

    this.interval = setInterval(() => {
      this.init()
    }, 13000)
  },
  destroyed () {
    clearInterval(this.interval)
  },
  methods: {
    init () {
      const checkboxPulse = this.$refs['pulse-focus-checkbox']
      const savePulse = this.$refs['pulse-focus-save']
      checkboxPulse.style.display = 'none'
      savePulse.style.display = 'none'
      this.priceValue = 100

      setTimeout(() => {
        checkboxPulse.style.display = 'block'
      }, 1000)

      setTimeout(() => {
        checkboxPulse.style.display = 'none'
        this.checked = true
      }, 3000)

      setTimeout(() => {
        this.priceValue = ''
      }, 5000)

      setTimeout(() => {
        this.priceValue = Math.round(this.price / 0.7)
      }, 8000)

      setTimeout(() => {
        savePulse.style.display = 'block'
      }, 10000)

      setTimeout(() => {
        savePulse.style.display = 'none'
        this.checked = false
      }, 13000)
    }
  }
}
</script>

import policy from '../policy/en'
import terms from '../terms/en'

export default {
  navigation: {
    title: 'NAVIGASYON',
    home: 'Ana sayfa',
    buyRobux: 'ROBUX SATIN ALIN',
    lk: 'KİŞİSEL ALAN',
    vk: 'VK grubu',
    discord: 'Diskord',
    telegram: 'Telegram',
    instagram: 'Instagram',
    tiktok: 'tiktok',
    dropdown: {
      history: 'Geçmiş',
      changePassword: 'Şifreyi değiştirin',
      refill: 'Doldur',
      logout: 'Çıkış'
    }
  },
  general: {
    warning: 'Dikkat!',
    error: 'Hata',
    gotIt: 'Anlaşıldı',
    confirm: 'DOĞRULAMA',
    or: 'veya',
    link: 'bağlantıya',
    transfer: 'Aktar',
    policy: 'GİZLİLİK POLİTİKASI',
    terms: 'KULLANIM ŞARTLARI',

    errors: {
      internal: 'Dahili hata, lütfen daha sonra tekrar deneyin',
      server: 'Server hatası'
    }
  },
  actions: {
    continue: 'Devam et',
    submit: 'Doğrula',
    buyRobux: 'ROBUX SATIN ALIN',
    openDiscord: 'DISKORDA GİDİN',
    openInstruction: 'TALİMATLARA GİDİN',
    createAccount: 'Hesap Oluşturun',
    giriş: 'HESAPTA GİRİŞ YAPIN',
    loginVK: 'VK ile giriş yapın',
    signin: 'Giriş yapın',
    enterPassword: 'Girin',
    skip: 'Atla',
    buy: 'Satın alın',
    buyOrder: 'Satın alın',
    tryAgain: 'Tekrar deneyin',
    create: 'Create',
    ready: 'Ready',
    edit: 'Edit'
  },
  payment: {
    refill: 'Bakiyeyi yeniden doldurma',
    total: 'Toplam',
    toPay: 'Ödemeye',
    pay: 'Öde',
    makeOrder: 'Sipariş için ödeme yapın',
    otherPaymentMethods: 'Diğer ödeme yöntemleri',
    enterSum: 'Doldurma tutarını girin',
    commission: 'Komisyon',
    notEnough: 'Ödemek için yeterli paranız yok!',
    problems: 'Eğer sizin herhangi bir sorununuz varsa, {support} birimimize yazın',
    support: 'destek',
    selectPaymentMethod: 'ÖDEME YÖNTEMİNİ SEÇİN'
  },
  order: {
    status: 'SİPARİŞ DURUMU',
    wait: 'SİPARİŞ İÇİN LÜTFEN BEKLEYİN',
    waitTime: 'KALDI',
    code: 'Kod 6 haneli olmalıdır',
    mailCode: 'E-Postadan gelen kodu girin',
    makeNewOrder: 'Başka bir sipariş oluştur',
    table: {
      nickname: 'Oyun kullanıcı adı',
      count: 'R$ miktarı',
      total: 'Toplam ruble'
    },
    descriptions: {
      wait: 'Siparişin tamamlanmasını bekleyin',
      notEnoughFunds: 'Hata. Sitede yeterli para yok!',
      systemError: 'Sistem hatası!',
      checkVip: 'VIP ROBLOX ayarlarını kontrol edin!',
      connectionError: "ROBLOX'a bağlanırken hata oluştu!",
      success: 'Sipariş başarıyla tamamlandı!',
      checkGamepass: 'Gamepass ayarlarını kontrol edin!'
    }
  },
  auth: {
    authorization: 'Yetki',
    registration: 'Kayıt',
    recovery: 'Yenilemek',
    change: 'Şifreyi değiştir',

    inputs: {
      email: 'E-posta girin',
      login: 'Kullanıcı adı girin',
      loginOrEmail: 'E-posta ve kullanıcı adı girin',
      currentPassword: 'Mevcut Şifre',
      newPassword: 'Yeni şifre',
      repeatNewPassword: 'Yeni şifreyi tekrar edin',
      password: 'Şifreyi girin',
      passwordSubmit: 'Şifreyi doğrulayın'
    },

    errors: {
      email: 'Yalnış E-posta!',
      falsePassword: 'Yanlış şifre!',
      empty: 'Alan boş',
      nickIsTooSmall: 'En az 4 sembolden oluşan bir isim girmelisiniz',
      inactiveEmail: 'E-posta geçersiz',
      passwordIsTooSmall: 'Parolanız en az 6 karakter uzunluğunda olmalıdır',
      passwordNotMatch: 'Şifreler uyuşmuyor',
      oneUppercase: 'Şifrenizde en az bir büyük harf bulunmalıdır',
      userExist: 'Bu verilere sahip kullanıcı artık',
      checkEmail: 'E-posta adresinizin/kullanıcı adınızın doğru olduğundan emin olun',
      checkPassword: 'Şifrenizin doğru olduğundan emin olun',
      changePasswordIncorrect: 'Şifre 6 ila 18 karakter arasında olmalı ve “A-z” harflerinden oluşmalıdır!'
    },

    welcome: {
      title: 'Hoş geldiniz',
      content: 'SİTEMİZE KAYDOLUN VE GÜVENLİ, HIZLI VE GÜVENLİ BİR ŞEKİLDE ROBUX SATIN ALMAYA BAŞLAYIN'
    },

    forget: 'Şifreyi unuttunuz mu?',
    remember: 'Şifreyi hatırladınız mı?',
    agreement: 'Ben {policy} yi kabul ediyorum',
    privacyPolicy: 'Gizlilik Politikası',
    haveAccount: 'Sizin artık bir hesabınız var mı?',
    codeSent: '{email} e-postaya şifreni yenilemek için kod gönderildi!',
    enterCode: '6 haneli kodu girin:',
    requestCode: 'Tekrar iste',

    changePasswordSuccess: {
      title: 'Harika!',
      content: 'Siz başarıyla şifrenizideğiştirdiniz! Mutlaka hatırlayın...'
    }
  },
  slider: {
    slide1: {
      title: '#01 GÜVENLE ROBUX SATIN AL!',
      content: 'Tüm oyun para birimi yasal olarak oyun geliştiricilerinden alınır.'
    },
    slide2: {
      title: '#2 EN İYİ FİYATLAR!',
      content: 'Sadece bizim mağazamızda en düşük fiyatlarla robux satın alabilirsin ve onları alman garanti edilir!'
    },
    slide3: {
      title: '#3 Bize müşteriler güveniyor!',
      content: 'Mağazamız üç yıldır var ve bu süre zarfında kendini kurdu!'
    }
  },
  faq: {
    title: 'ROBUX SATIN ALMAK İSTER MİSİNİZ?',
    content: 'UYGUN FİYATLAR, HIZLI NAKLİYE!',
    items: {
      item1: {
        index: '01',
        title: 'Sipariş edin',
        content: 'Kaç tane robux satın alacağınızı ve roblox hesabınızı seçin'
      },
      item2: {
        index: '02',
        title: 'Ödeme yap',
        content: 'Sipariş için herhangi bir uygun şekilde ödeme yapın'
      },
      item3: {
        index: '03',
        title: 'ROBUX ALIN',
        content: '5 gün içinde oyun hesabınıza robux alın!'
      }
    }
  },
  help: {
    title: 'Robux satın alırken sorun mu yaşıyorsunuz?',
    text1: 'Desteğimiz, sunucuda discord veya kişisel Instagram mesajlarında size kesinlikle yardımcı olacaktır!',
    text2: 'Talimatları şu şekilde de kullanabilirsiniz:'
  },
  footer: {
    privacy: 'GİZLİLİK POLİTİKASI',
    terms: 'KULLANICI SÖZLEŞMESİ',
    contacts: 'İLETİŞİM BİLGİLERİ'
  },
  methods: {
    title: 'HANGİ SATIN ALMA YÖNTEMİNİ KULLANMAK İSTERSİNİZ?',
    hintError: {
      title: 'Hata!',
      content: "Sizin artık Roblox Premium'unuz var mı?!"
    },
    method: 'Yöntem',
    robux: 'robux',
    premium: 'Premium',
    transfer: 'Aktarım',
    logpass: 'log+pass',
    available: 'Stokda mevcut',
    notAvailable: 'Stokta mevcut değil'
  },
  cookie: {
    whatIsCookie: 'ÇEREZ nedir?',
    checkInstruction: 'ÇEREZ TALİMATLARINI İNCELEYİN',
    enter: 'CEREZİ GİRİN',
    description: {
      part1: 'Çerezlerin kendileri tehlikeli değildir - bu sıradan metin dosyalarıdır. Bilgisayarda işlem başlatamazlar ve genellikle işletim sistemi ile etkileşime girerler. Ancak, önceki çevrimiçi etkinliklerinizi takip etmek veya yetkiniz olmadan hesaplarınıza giriş yapmak için ele geçirilebilir veya çalınabilirler.',
      part2: 'Çerezlerde yazılan bilgiler gönderilmeden önce genellikle şifrelenir ve çerezlerin kendileri HTTPS protokolü üzerinden iletilir. Bu, kullanıcı verilerinin korunmasına yardımcı olur, ancak şifrelemeyi uygulamak ve güvenli bir şekilde göndermek site geliştiricisine kalmıştır. Ziyaretçiler yalnızca her şeyin doğru şekilde kurulduğunu umabilir. Kullanıcı kendi adına yalnızca tarayıcının tanımlama bilgilerini kullanmasını engelleyebilir veya bunları zaman zaman temizleyebilir.'
    }
  },
  transfer: {
    robuxDelivery: 'Robux 5 gün (120 saat) içinde kredilendirilecek',
    available: 'Mevcut:',
    robuxCount: 'Roboux sayısı',
    minumum: "Minimum {min} R$'dan itibaren",
    nickname: 'Oyun Kullanıcı adı',
    notFound: 'Hiçbir şey bulunamadı',
    checkPlaces: 'LÜTFEN EN AZ 1 GENEL YERİNİZ OLDUĞUNU KONTROL EDİN',
    vipPrice: 'VIP sunucu fiyatını {price} olarak ayarla',
    changePrice: 'Fiyatı değiştir',
    orderSuccess: "Robux'un alındığını {here} hesabınıza takip edebilirsiniz",
    orderError: 'Paralar ROBUXPIER hesap bakiyesine iade edildi. Tekrar deneyin:)',

    here: 'burada',
    gamepass: {
      checkData: 'Check the correctness of the data in Gamepass!',
      creating: 'CREATING',
      settingUp: 'SETUP',
      createGamepass: 'Create a Game-pass, you can use <a href="https://backend-pier.com/luckyteam.jpg" target="_blank">this </a>image',
      checkInstruction: 'View the instructions and set the price <span>{price} R$</span>'
    },
    errors: {
      insufficientBalance: 'Yetersiz bakiye',
      notAuthorized: 'Yetkili değilsiniz',
      serverError: 'Sunucu hatası',
      robuxNotAvailable: 'Satın alınamayan robux miktarı',

      priceNotNumber: 'Fiyat bir sayı olmalıdır',
      robuxMinimum: "{robux} robux'tan az sipariş verilemez",
      halfBalance: 'Bakiyenin yarısından fazlası sipariş edilemez: {balance} robux',
      robuxMustDivide: 'R$ {orders} e bölünmelidir. <span class="min">{closestMin}R$</span> ve ya <span class="max">{closestMax}R$</span> deneyin'
    },
    selectYourPlace: 'Size ait olanı seçin'
  },
  logpass: {
    enterNickname: 'Kullanıcı adı girin',
    buyRobuxTitle: 'Robux satın alın',
    codes: {
      code_request: 'Lütfen e-postanıza gelen kodu giriniz',
      order_awaiting: 'Siparişi bekle',
      code_request_awaiting: 'Postadan kod isteği bekleniyor',

      password_error: "ROBLOX'ta geçersiz şifre",
      code_error: 'E-postadan geçersiz 2 adımlı doğrulama kodu',
      cookie_error: 'Hata. Geçersiz yetkilendirme verileri',
      email_timeout_error: 'Siz kodu belirtmediniz! Tekrar deneyin ve mümkün olan en kısa sürede kodu girmeye çalışın!',
      error: 'Hata. Tekrar deneyin',
      success: 'Sipariş başarıyla tamamlandı',
      waiting: 'Sipariş için bekleyin'
    }
  },
  premium: {
    buyPremium: 'PREMIUM SATIN AL',
    alreadyExist: 'Siz de artık Roblox Premium var mı?!',
    subscribe: {
      title: '{percent} ROBUX`LARA!',
      subtitle: 'Premium abonelik ile daha fazla robux alacaksınız'
    },
    getRbx: '{rbx} hesabınıza alın',
    buyWithProfit: 'KAR İLE SATIN ALIN!',
    currencyPerMonth: '{currency} bir aylık',
    toPay: 'Toplam ödenecek miktar: ',
    premiumTime: 'Premium abonelik yalnızca {time} için satın alınır',
    oneMonth: 'Otomatik yenileme olmadan 1 ay',
    enterPromocode: 'Promosyon kodunu girin',
    ordering: 'Sipariş hazırla',
    mailCode: 'LÜTFEN POSTADAN KOD İSTEĞİNİZİ BEKLEYİNİZ',

    correctSum: 'Doğru miktarı girin',
    minRefillSum: 'İkmal tutarı en az 5 ruble olmalıdır'
  },
  supportChat: {
    hint: 'Bir sorunuz var mı?',
    title: 'OYUNCULARIN DESTEĞİ',
    operator: 'Asistanınız',
    actions: 'Eylem seçenekleri',
    anket: 'Operatörle iletişime geçmek için kendinizi tanıtın. RobuxPier ile ilgili sorularınıza mutlaka cevap alacaksınız.',
    name: 'Ad',
    email: 'E-posta',
    vk: "Vkontakte'ye bağlantı",
    send: 'Gönder',
    enterMessage: 'Mesajınızı girin...'
  },
  qiwi: {
    details: ' DOLDURMA DETAYLARI',
    passport: 'Rus pasaportunuz var mı?',
    noPassport: 'Rus Pasaportunuz olmadığı için Qiwi ödeme sisteminde doğrulamayı geçemeyeceksiniz.',
    hasPassport: 'Daha düşük bir ücret almak için Qiwi web sitesinde doğrulama yapmanız gerekiyor.',
    noPassportCommission: 'Komisyon %7,5 olacak',
    hasPassportCommission: 'Komisyon %3 olacak',
    yes: 'Evet',
    no: 'Hayır'
  },
  history: {
    nickname: 'Oyun takma adı',
    sumRobux: 'Toplam, R$',
    sumRub: 'Toplam',
    status: 'Durum',
    description: 'Açıklama',
    timer: 'Zamanlayıcı',

    error: 'Hata',
    wait: 'Beklenti',
    success: 'Başarıyla',
    orderCount: '{order}, {length} kayıttan sonra gösteriliyor'
  },
  policy,
  terms
}

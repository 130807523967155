<template>
  <div class="main__form transfer__wrapper">
    <div class="transfer__form">
      <Loader v-if="loading.length > 0" />
      <div class="transfer__form-logo">
        <Logo dark />
      </div>
      <div class="transfer__form-count">
        <p>{{ $t('transfer.available') }}</p>
        <small>{{ rbxAmount }} R$</small>
      </div>
      <div class="transfer__form-content">
        <div class="transfer__form-content__block">
          <div class="transfer__form-content__bg">
            <p>R$</p>
          </div>
          <input v-model="totalRobux" @input="validatePrice" class="transfer__form-content__input" type="text"
            :placeholder="$t('transfer.robuxCount')">
        </div>
        <p v-if="error.show === false" class="transfer__form-content__hint">
          {{ $t('transfer.minumum', { min: 50 }) }}
        </p>
        <p v-if="error.show && error.status === false" class="transfer__form-content__error" v-html="error.message"></p>
        <div class="select">
          <p @click="selectItem(100, $event)" class="select__item">100 R$</p>
          <p @click="selectItem(250, $event)" class="select__item">250 R$</p>
          <p @click="selectItem(400, $event)" class="select__item">400 R$</p>
          <p @click="selectItem(600, $event)" class="select__item">600 R$</p>
        </div>
      </div>
      <button class="transfer__form-btn" @click="changeModal">
        {{ $t('actions.continue') }}
      </button>
      <div class="transfer__form-footer">
        <div class="count">
          <p>{{ $t('payment.total') }}: <span class="blue">{{ totalRobuxText }} R$</span></p>
        </div>
        <div class="total">
          <p>{{ $t('payment.toPay') }}: <span class="blue">{{ getMoneyTotal().toFixed(2) }} {{
            currencyMap[$store.state.currency] }}</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/LoaderBar/LoaderBar.vue'

import './fastPassMainForm.scss'
import Logo from '@/components/Logo/Logo.vue'

export default {
  name: 'FastPassMainForm',
  components: {
    Loader,
    Logo
  },
  inject: ['currencyMap'],
  data () {
    return {
      totalRobux: 600,
      totalRobuxText: '600.00',
      rates: {
        rub: 0,
        usd: 0,
        eur: 0
      },
      rbxAmount: null,
      error: {
        status: false,
        show: false,
        message: ''
      },
      loading: []
    }
  },
  created () {
    const userId = localStorage.getItem('user_id') || 'default'

    this.loading.push(true)
    this.loading.push(true)

    fetch(`https://prices.backend-pier.com/get_rates/${userId}`)
      .then((res) => res.json())
      .then((data) => {
        this.rates = data.before

        this.loading.pop(true)
      })
      .catch((err) => console.log(err))

    fetch('https://transfer.backend-pier.com/in_stock')
      .then((res) => res.json())
      .then((data) => {
        this.loading.pop(true)
        if (data.status) {
          this.rbxAmount = data.robux_amount
          this.validatePrice()
        }
      })
      .catch((err) => console.log(err))
  },
  methods: {
    changeModal () {
      if (this.error.show) {
        return
      }
      this.$emit('changeModal', {
        modal: 'TransferCharacters',
        data: [this.totalRobux, this.getMoneyTotal]
      })
    },
    getBeforeRate () {
      const rateType = {
        rub: 'rate',
        usd: 'rate_usd',
        eur: 'rate_eur'
      }

      return this.rates[rateType[this.$store.state.currency]]
    },
    getMoneyTotal () {
      return Math.ceil(Number(this.totalRobux * this.getBeforeRate()) * 100) / 100
    },
    selectItem (price, event) {
      const target = event.target

      const selected = document.querySelector('.select__item.sel')
      if (selected) {
        selected.classList.remove('sel')
      }

      this.totalRobux = Number(price)
      this.totalRobuxText = Number(price).toFixed(2)

      this.validatePrice()

      if (this.error.status === false) {
        return
      }

      target.classList.add('sel')
    },
    validatePrice (event) {
      if (isNaN(this.totalRobux)) {
        this.error.status = false
        this.error.show = true
        this.error.message = this.$t('transfer.errors.priceNotNumber')

        return
      } else if (this.totalRobux < 50) {
        this.error.status = false
        this.error.show = true
        this.error.message = this.$t('transfer.errors.robuxMinimum', { robux: 50 })

        return
      } else if (this.totalRobux > (this.rbxAmount / 2)) {
        this.error.status = false
        this.error.show = true
        this.error.message = this.$t('transfer.errors.halfBalance', { balance: (this.rbxAmount / 2).toFixed(2) })

        return
      } else if (this.totalRobux > 9000) {
        this.error.status = false
        this.error.show = true
        this.error.message = 'The maximum purchase amount is temporarily limited to 9k R$'

        return
      }

      this.error.status = true
      this.error.show = false
      this.error.message = ''

      if (event) {
        this.totalRobuxText = parseFloat(event.target.value).toFixed(2)
      }
    },
    isInteger (num) {
      return (num ^ 0) === num
    }
  }
}
</script>

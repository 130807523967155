<template>
  <div class="change-password">
    <div class="change-password__main" v-if="not_succesfull">
      <Loader v-if="loaderOn"/>
        <p class="change-password__main__title">{{ $t('auth.change') }}</p>
        <div
          class="change-password__main__form"
          :class="{ wrong: errors.password.status === false && errors.password.show }"
        >
          <p class="label">{{ errors.password.message }}</p>
          <div class="change-password__main__form__input">
          <div class="change-password__main__form__input__img"><img src="@/assets/images/SignUp/lock.svg" alt=""></div>
            <div class="change-password__main__form__input__in">
              <input
                type="password"
                :placeholder="$t('auth.inputs.currentPassword')"
                v-model="values.password"
                @change="validateChangePassword"
                @keyup.enter="change"
              >
            </div>
          </div>
        </div>
        <div
          class="change-password__main__form"
          :class="{ wrong: errors.newPassword.status === false && errors.newPassword.show }"
        >
          <p class="label">{{ errors.newPassword.message }}</p>
          <div class="change-password__main__form__input">
          <div class="change-password__main__form__input__img"><img src="@/assets/images/SignUp/lock.svg" alt=""></div>
            <div class="change-password__main__form__input__in">
              <input
                type="password"
                :placeholder="$t('auth.inputs.newPassword')"
                v-model="values.newPassword"
                @change="validateNewPassword"
                @keyup.enter="change"
              >
              <img id="change-password_pas" @click="eye('change-password_pas')" src="@/assets/images/SignUp/close-eye.svg" alt="">
            </div>
          </div>
        </div>
        <div
          class="change-password__main__form"
          :class="{ wrong: errors.confirmPassword.status === false && errors.confirmPassword.show }"
        >
          <p class="label">{{ errors.confirmPassword.message }}</p>
          <div class="change-password__main__form__input">
          <div class="change-password__main__form__input__img"><img src="@/assets/images/SignUp/lock.svg" alt=""></div>
            <div class="change-password__main__form__input__in">
              <input
                type="password"
                :placeholder="$t('auth.inputs.repeatNewPassword')"
                v-model="values.confirmPassword"
                @change="validateConfirmPassword"
                @keyup.enter="change"
              >
              <img id="change-password_pas-rep" @click="eye('change-password_pas-rep')" src="@/assets/images/SignUp/close-eye.svg" alt="">
            </div>
          </div>
        </div>
        <button class="change-password__main__btn-go" @click="change">
          <p>{{ $t('actions.continue') }}</p>
        </button>
    </div>
    <div class="change-password__time" v-if="succesfull">
      <img src="@/assets/images/ChangePassword/anchor.png" alt="">
    </div>
    <div class="change-password__succesfull" v-if="succesfull">
      <div class="change-password__succesfull__gradient">
        <div class="change-password__succesfull__gradient_1"></div>
      </div>
      <div class="change-password__succesfull__main">
        <p class="change-password__succesfull__main__title">{{ $t('auth.changePasswordSuccess.title') }}</p>
        <p class="change-password__succesfull__main__text">{{ $t('auth.changePasswordSuccess.content') }}</p>
      </div>
    </div>
    <div class="change-password__btn-close" @click="$store.commit('stateChangePassword')">
      <div class="change-password__btn-close__img"><img src="@/assets/images/SignUp/close.svg" alt=""></div>
    </div>
  </div>
</template>

<script>
import './changepassword.scss'

import Loader from '@/components/LoaderBar/LoaderBar'

import { validatePassword } from './../../utils/validate'

export default {
  name: 'ChangePassword',
  components: {
    Loader
  },
  data () {
    return {
      loaderOn: false,
      password: '',
      new_password: '',
      new_passwoed_repeat: '',
      succesfull: false,
      not_succesfull: true,
      values: {
        password: '',
        newPassword: '',
        confirmPassword: ''
      },
      errors: {
        password: {
          status: false,
          show: false,
          message: ''
        },
        newPassword: {
          status: false,
          show: false,
          message: ''
        },
        confirmPassword: {
          status: false,
          show: false,
          message: ''
        }
      }
    }
  },
  methods: {
    eye (el) {
      const icon = document.querySelector(`#${el}`)
      const input = icon.parentElement.querySelector('input')
      if (icon.src.includes('close-eye')) {
        icon.src = require('@/assets/images/SignUp/open-eye.svg')
        input.type = 'text'
      } else {
        icon.src = require('@/assets/images/SignUp/close-eye.svg')
        input.type = 'password'
      }
      return false
    },
    change () {
      this.validateChangePassword()
      this.validateNewPassword()
      this.validateConfirmPassword()

      let error = false

      Object.keys(this.errors).forEach((key) => {
        if (this.errors[key].status === false) {
          error = true
        }
      })

      if (error) {
        return
      }

      const requestBody = JSON.stringify({
        old: this.values.password,
        new: this.values.newPassword
      })

      const requestHeaders = new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.$cookies.get('access_token')}`
      })

      const requestParams = {
        method: 'POST',
        body: requestBody,
        headers: requestHeaders
      }

      this.loaderOn = true

      fetch('https://users.backend-pier.com/api/v1/user/update-password', requestParams)
        .then((res) => res.json())
        .then((data) => {
          this.loaderOn = false

          if (data.status >= 400) {
            const texts = {
              400: this.$t('general.errors.internal'),
              401: this.$t('auth.errors.checkPassword'),
              500: this.$t('general.errors.server')
            }
            this.errors.password.status = false
            this.errors.password.show = true
            this.errors.password.message = texts[data.status]
            return
          }

          if (data.status) {
            this.$store.commit('stateChangePassword')
          }
        })
        .catch((err) => console.log(err))
    },
    validateChangePassword () {
      let errorMessage = ''
      let passwordState = true

      if (this.values.password.length === 0) {
        errorMessage = this.$t('auth.errors.empty')
        passwordState = false
      } else {
        errorMessage = ''
        passwordState = true
      }

      this.errors.password.status = passwordState
      this.errors.password.show = !passwordState
      this.errors.password.message = errorMessage
    },
    validateNewPassword () {
      let errorMessage = ''
      let passwordState = true

      if (this.values.newPassword.length === 0) {
        errorMessage = this.$t('auth.errors.empty')
        passwordState = false
      } else if (validatePassword(this.values.newPassword) === false) {
        errorMessage = this.$t('auth.errors.changePasswordIncorrect')
        passwordState = false
      } else if (this.values.newPassword === this.values.confirmPassword) {
        this.errors.confirmPassword.status = true
      } else {
        errorMessage = ''
        passwordState = true
      }

      this.errors.newPassword.status = passwordState
      this.errors.newPassword.show = !passwordState
      this.errors.newPassword.message = errorMessage
    },
    validateConfirmPassword () {
      let errorMessage = ''
      let passwordState = true

      if (this.values.confirmPassword !== this.values.newPassword) {
        errorMessage = 'Пароли не совпадают'
        passwordState = false
      } else {
        errorMessage = ''
        passwordState = true
      }

      this.errors.confirmPassword.status = passwordState
      this.errors.confirmPassword.show = !passwordState
      this.errors.confirmPassword.message = errorMessage
    }
  }
}
</script>

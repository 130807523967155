<template>
  <div class="forget-password">
    <div class="forget-password__step1" v-if="step===1">
      <Loader v-if="loaderOn"/>
      <p class="forget-password__title">{{ $t('auth.recovery') }}</p>
      <div
        class="forget-password__form"
        :class="{ wrong: errors.email.status === false && errors.email.show }"
      >
        <p class="label">{{ errors.email.message }}</p>
        <div class="forget-password__form__input">
        <div class="forget-password__form__input__img"><img src="@/assets/images/ForgetPassword/mail.svg" alt=""></div>
            <div class="forget-password__form__input__in">
                <input
                  type="email"
                  :placeholder="$t('auth.inputs.email')"
                  v-model="values.email"
                  @change="validateRecoveryEmail"
                  @keyup.enter="toStep2"
                >
            </div>
        </div>
      </div>
      <button
        @click="toStep2"
        class="forget-password__btn-go"
        :class="{ inactive: errors.email.status === false }"
        id="btnGo_step1"
      >
        {{ $t('actions.continue') }}
      </button>
      <p class="forget-password__remember-pas" @click="$store.commit('stateSignIn'), $store.commit('stateForgetPassword')">{{ $t('auth.remember') }} <span>{{ $t('actions.enterPassword') }}</span></p>
    </div>
    <div class="forget-password__step2" v-if="step===2">
      <Loader v-if="loaderOn"/>
      <p class="forget-password__title">{{ $t('auth.recovery') }}</p>
      <div class="forget-password__form">
        <p class="label">{{ $t('auth.errors.email') }}</p>
        <div class="forget-password__form__input">
        <div class="forget-password__form__input__img"><img src="@/assets/images/ForgetPassword/mail.svg" alt=""></div>
            <div class="forget-password__form__input__in">
                <input :value="values.email" type="email" :placeholder="$t('auth.inputs.email')" readonly style="opacity: 0.5;">
                <img src="@/assets/images/ForgetPassword/pen.svg" alt="" @click="toStep1">
            </div>
        </div>
      </div>

      <i18n path="auth.codeSent" tag="p" class="forget-password__step2__inf">
        <template v-slot:email>
          <span>{{ mail }}</span>
        </template>
      </i18n>

      <div class="forget-password__step2__code">
        <p class="forget-password__step2__code__head">{{ $t('auth.enterCode') }}</p>
        <div
          class="forget-password__step2__code__inputs"
          :class="{ wrong: errors.code.status === false && errors.code.show }"
        >
          <input type="number" @focus="focusCode(0)" @input="enterCode(0)">
          <input type="number" @focus="focusCode(1)" @input="enterCode(1)">
          <input type="number" @focus="focusCode(2)" @input="enterCode(2)">
          <input type="number" @focus="focusCode(3)" @input="enterCode(3)">
          <input type="number" @focus="focusCode(4)" @input="enterCode(4)">
          <input type="number" @focus="focusCode(5)" @input="enterCode(5)">
        </div>
        <p class="forget-password__step2__code__timer" v-if="timers.value > 0"><span>{{ $t('auth.requestCode') }}: </span>0:{{ ('0' + timers.value).slice(-2) }}</p>
        <p class="forget-password__step2__code__timer" v-if="timers.value <= 0"><span @click="resendCode" style="cursor: pointer;">{{ $t('auth.requestCode') }}</span></p>
      </div>
      <button id="btnGo_step2" class="forget-password__btn-go inactive" @click="toStep3">{{ $t('actions.continue') }}</button>
      <p class="forget-password__remember-pas" @click="$store.commit('stateSignIn'), $store.commit('stateForgetPassword')">{{ $t('auth.remember') }} <span>{{ $t('actions.enterPassword') }}!</span></p>
    </div>
    <div class="forget-password__step3" v-if="step===3">
      <Loader v-if="loaderOn"/>
      <p class="forget-password__title">{{ $t('auth.recovery') }}</p>
      <div
        class="forget-password__form"
        :class="{ wrong: errors.password.status === false && errors.password.show }"
      >
        <p class="label">{{ errors.password.message }}</p>
        <div class="forget-password__form__input">
        <div class="forget-password__form__input__img"><img src="@/assets/images/SignUp/lock.svg" alt=""></div>
            <div class="forget-password__form__input__in">
                <input
                  type="password"
                  :placeholder="$t('auth.inputs.password')"
                  v-model="values.password"
                  @change="validateRecoverPassword"
                  @keyup.enter="toAuth"
                >
                <img id="fp_pas" src="@/assets/images/SignUp/close-eye.svg" alt="" @click="eye('fp_pas')">
            </div>
        </div>
      </div>
      <div
        class="forget-password__form"
        :class="{ wrong: errors.password2.status === false && errors.password2.show }"
      >
        <p class="label">{{ errors.password2.message }}</p>
        <div class="forget-password__form__input">
        <div class="forget-password__form__input__img"><img src="@/assets/images/SignUp/lock.svg" alt=""></div>
            <div class="forget-password__form__input__in">
                <input
                  type="password"
                  :placeholder="$t('auth.inputs.passwordSubmit')"
                  v-model="values.password2"
                  @change="validateRecoverPassword2"
                  @keyup.enter="toAuth"
                >
                <img id="fp_pasRep" src="@/assets/images/ForgetPassword/close-eye.svg" alt="" @click="eye('fp_pasRep')">
            </div>
        </div>
      </div>
      <button
        id="btnGo_step3" class="forget-password__btn-go"
        @click="toAuth"
        :disabled="this.errors.password.status === false || this.errors.password2.status === false"
      >{{ $t('actions.submit') }}</button>
    </div>
    <div class="forget-password__btn-close" @click="$store.commit('stateForgetPassword')">
      <div class="forget-password__btn-close__img"><img src="@/assets/images/ForgetPassword/close.svg" alt=""></div>
    </div>
  </div>
</template>

<script>
import './forgetpassword.scss'

import Loader from '@/components/LoaderBar/LoaderBar'

import { validateEmail, validatePassword } from './../../utils/validate'

export default {
  name: 'ForgetPassword',
  components: {
    Loader
  },
  data () {
    return {
      step: 1,
      loaderOn: false,
      mail: '',
      password: '',
      passwordRepeat: '',
      values: {
        email: '',
        password: '',
        password2: ''
      },
      timers: {
        value: 59,
        id: null
      },
      errors: {
        email: {
          status: false,
          show: false,
          message: ''
        },
        password: {
          status: false,
          show: false,
          message: ''
        },
        password2: {
          status: false,
          show: false,
          message: ''
        },
        code: {
          status: false,
          show: false,
          message: ''
        }
      },
      code: ''
    }
  },
  methods: {
    enterEmail () {
      const btnGo = document.querySelector('#btnGo_step1')
      if (this.mail.length) {
        btnGo.classList.remove('inactive')
      } else {
        btnGo.classList.add('inactive')
      }
      return false
    },
    resetTimer () {
      this.timers.value = 59

      this.timers.id = setInterval(() => {
        this.timers.value -= 1

        if (this.timers.value <= 0) {
          clearInterval(this.timers.id)
        }
      }, 1000)
    },
    resendCode () {
      this.resetTimer()
    },
    toStep1 () {
      this.step = 1
    },
    toStep2 () {
      this.loaderOn = true

      this.validateRecoveryEmail()

      if (this.errors.email.status === false) {
        return
      }

      const requestBody = JSON.stringify({
        login: this.values.email
      })

      const requestHeaders = new Headers({
        'Content-Type': 'application/json'
      })

      const requestParams = {
        method: 'POST',
        headers: requestHeaders,
        body: requestBody
      }

      fetch('https://users.backend-pier.com/api/v1/user/reset-password', requestParams)
        .then((res) => res.json())
        .then((data) => {
          this.loaderOn = false
          if (data.status) {
            this.step = 2
            this.resetTimer()
          }
        })
        .catch((err) => console.log(err))
    },
    focusCode (i) {
      const list = document.querySelector('.forget-password__step2__code__inputs').querySelectorAll('input')
      list[i].select()
    },
    enterCode (i) {
      const list = document.querySelector('.forget-password__step2__code__inputs').querySelectorAll('input')
      if (list[i].value.length === 6) {
        const key = `${list[i].value}`
        for (let k = 0; k < 6; k++) {
          list[k].value = key[k]
        }
      } else {
        if (list[i].value.length > 1) {
          list[i].value = list[i].value[list[i].value.length - 1]
        }
        if (list[i].value.length === 1) {
          if (i < list.length - 1) {
            list[i + 1].focus()
          }
        }
      }
      let count = 0
      for (let k = 0; k < 6; k++) {
        if (list[k].value.length > 0) {
          count += 1
        }
      }
      const btnGo = document.querySelector('#btnGo_step2')
      if (count === 6) {
        btnGo.classList.remove('inactive')
      } else {
        btnGo.classList.add('inactive')
      }
      this.code = ''
      for (let k = 0; k < 6; k++) {
        this.code += `${list[k].value}`
      }

      if (this.code.length === 6) {
        this.errors.code.status = true
      }
    },
    toStep3 () {
      this.loaderOn = true

      if (this.errors.code.status === false || this.code.length !== 6) {
        return
      }

      const requestBody = JSON.stringify({
        token: this.code
      })

      const requestHeaders = new Headers({
        'Content-Type': 'application/json'
      })

      const requestParams = {
        method: 'POST',
        body: requestBody,
        headers: requestHeaders
      }

      this.loaderOn = true

      fetch('https://users.backend-pier.com/api/v1/user/verify-token', requestParams)
        .then((res) => res.json())
        .then((data) => {
          this.loaderOn = false

          if (data.status === false) {
            this.errors.code.status = false
            this.errors.code.show = true
            this.errors.code.message = 'Неверный код'
          } else {
            this.step = 3
          }
        })
        .catch((err) => console.log(err))
    },
    checkPassword () {
      const btnGo = document.querySelector('#btnGo_step3')
      if (this.password === this.passwordRepeat && this.password.length > 0) {
        btnGo.classList.remove('inactive')
      } else {
        btnGo.classList.add('inactive')
      }
    },
    eye (el) {
      const icon = document.querySelector(`#${el}`)
      const input = icon.parentElement.querySelector('input')
      if (icon.src.includes('close-eye')) {
        icon.src = require('@/assets/images/ForgetPassword/open-eye.svg')
        input.type = 'text'
      } else {
        icon.src = require('@/assets/images/ForgetPassword/close-eye.svg')
        input.type = 'password'
      }
      return false
    },
    toAuth () {
      this.loaderOn = true

      if (this.errors.password.status === false || this.errors.password2.status === false) {
        return
      }

      const requestBody = JSON.stringify({
        token: this.code,
        new: this.values.password
      })

      const requestHeaders = new Headers({
        'Content-Type': 'application/json'
      })

      const requestParams = {
        method: 'POST',
        body: requestBody,
        headers: requestHeaders
      }

      fetch('https://users.backend-pier.com/api/v1/user/update-password-token', requestParams)
        .then((res) => res.json())
        .then((data) => {
          this.loaderOn = false

          if (data.status) {
            this.$store.commit('stateSignIn')
            this.$store.commit('stateForgetPassword')
          }
        })
        .catch((err) => console.log(err))
    },
    validateRecoveryEmail () {
      let errorMessage = ''
      let emailState = true

      if (this.values.email.length === 0) {
        errorMessage = this.$t('auth.errors.empty')
        emailState = false
      } else if (validateEmail(this.values.email) === false) {
        errorMessage = 'Введённая почта неверная!'
        emailState = false
      } else {
        errorMessage = ''
        emailState = true
      }

      this.errors.email.status = emailState
      this.errors.email.show = !emailState
      this.errors.email.message = errorMessage
    },
    validateRecoverPassword () {
      let errorMessage = ''
      let passwordState = true

      this.errors.password2.status = false

      if (this.values.password.length === 0) {
        errorMessage = this.$t('auth.errors.empty')
        passwordState = false
      } else if (validatePassword(this.values.password) === false) {
        errorMessage = this.$t('auth.errors.changePasswordIncorrect')
        passwordState = false
      } else if (this.values.password === this.values.password2) {
        this.errors.password2.status = true
      } else {
        errorMessage = ''
        passwordState = true
      }

      this.errors.password.status = passwordState
      this.errors.password.show = !passwordState
      this.errors.password.message = errorMessage
    },
    validateRecoverPassword2 () {
      let errorMessage = ''
      let passwordState = true

      if (this.values.password2 !== this.values.password) {
        errorMessage = 'Пароли не совпадают'
        passwordState = false
      } else {
        errorMessage = ''
        passwordState = true
      }

      this.errors.password2.status = passwordState
      this.errors.password2.show = !passwordState
      this.errors.password2.message = errorMessage
    }
  }
}
</script>

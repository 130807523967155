import Vue from 'vue'
import VueCookies from 'vue-cookies'
import App from './components/App/App.vue'
import router from './router'
import store from './store'
import { i18n } from './i18n/index'
import './utils/rounding'
import VueMeta from 'vue-meta'

Vue.use(VueCookies)
Vue.use(VueMeta)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

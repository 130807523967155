<template>
  <div class="sign-in">
    <Captcha v-if="showCaptcha" @hideCaptcha="hideCaptcha" @onVerify="verifyToken" />

    <div class="sign-in__main">
      <Loader v-if="isLoading" />
      <p class="sign-in__main__title">{{ $t("auth.authorization") }}</p>
      <div
        class="sign-in__main__form"
        :class="{ wrong: errors.login.status === false && errors.login.show }"
      >
        <p class="label">{{ errors.login.message }}</p>
        <div class="sign-in__main__form__input">
          <div class="sign-in__main__form__input__img">
            <img src="@/assets/images/SignIn/login.svg" alt="" />
          </div>
          <div class="sign-in__main__form__input__in">
            <input
              type="text"
              :placeholder="$t('auth.inputs.loginOrEmail')"
              v-model="values.login"
              @change="validateLogin"
              @keyup.enter="validateCaptcha"
            />
          </div>
        </div>
      </div>
      <div
        class="sign-in__main__form"
        :class="{
          wrong: errors.password.status === false && errors.password.show,
        }"
      >
        <p class="label">{{ errors.password.message }}</p>
        <div class="sign-in__main__form__input">
          <div class="sign-in__main__form__input__img">
            <img src="@/assets/images/SignIn/lock.svg" alt="" />
          </div>
          <div class="sign-in__main__form__input__in">
            <input
              type="password"
              :placeholder="$t('auth.inputs.password')"
              v-model="values.password"
              @change="validatePassword"
              @keyup.enter="validateCaptcha"
            />
            <img
              src="@/assets/images/SignIn/close-eye.svg"
              alt=""
              @click="eye('sign-in_pas-eye')"
              id="sign-in_pas-eye"
            />
          </div>
        </div>
      </div>

      <p
        class="sign-in__main__forget-pas"
        @click="
          $store.commit('stateSignIn'), $store.commit('stateForgetPassword')
        "
      >
        {{ $t("auth.forget") }}
      </p>
      <button class="sign-in__main__btn-signin" @click="validateCaptcha">
        <img src="@/assets/images/SignIn/logout.svg" alt="" />
        <p>{{ $t("actions.login") }}</p>
      </button>
      <template v-if="language === 'ru'">
<!--        <p class="sign-in__main__or">{{ $t("general.or") }}</p>
        <a :href="vkUrl" class="sign-in__main__btn-vk">
          <img src="@/assets/images/SignIn/vk.svg" alt="" />
          <p>{{ $t("actions.loginVK") }}</p>
        </a>-->
      </template>
    </div>
    <div class="sign-in__welcome">
      <p class="sign-in__welcome__title">{{ $t("auth.welcome.title") }}</p>
      <p class="sign-in__welcome__inf">{{ $t("auth.welcome.content") }}</p>
      <button
        class="sign-in__welcome__btn"
        @click="$store.commit('stateSignIn'), $store.commit('stateSignUp')"
      >
        <img src="@/assets/images/SignIn/plus.svg" alt="" />
        <p>{{ $t("actions.createAccount") }}</p>
      </button>
    </div>
    <div class="sign-in__btn-close" @click="$store.commit('stateSignIn')">
      <div class="sign-in__btn-close__img">
        <img src="@/assets/images/SignIn/close.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import './signin.scss'

import { mapGetters } from 'vuex'
import Loader from '@/components/LoaderBar/LoaderBar'
import Captcha from '@/components/Captcha/Captcha'
import * as intercom from '@/utils/intercom'
export default {
  name: 'SignIn',
  components: {
    Loader,
    Captcha
  },
  data () {
    return {
      isLoading: false,
      showCaptcha: false,
      vkUrl: '',
      login: '',
      password: '',
      values: {
        login: '',
        password: ''
      },
      token: '',
      errors: {
        login: {
          status: '',
          show: '',
          message: ''
        },
        password: {
          status: '',
          show: '',
          message: ''
        }
      }
    }
  },
  created () {
    fetch('https://users.backend-pier.com/api/v1/user/vkurl')
      .then((res) => res.json())
      .then((data) => {
        this.vkUrl = data.vk_url
      })
      .catch((err) => console.log(err))
  },
  computed: {
    ...mapGetters(['language'])
  },
  methods: {
    hideCaptcha () {
      this.showCaptcha = false
    },
    verifyToken (token) {
      this.hideCaptcha()
      this.token = token
      this.signIn()
    },
    validateCaptcha () {
      this.showCaptcha = true
    },
    signIn () {
      if (!this.token) return
      this.isLoading = true

      this.validateLogin()
      this.validatePassword()

      let error = false

      Object.keys(this.errors).forEach((key) => {
        if (this.errors[key].status === false) {
          error = true
        }
      })

      if (error) {
        this.isLoading = false
        return
      }

      const requestBody = JSON.stringify({
        login: this.values.login,
        password: this.values.password,
        clcaptcha: this.token
      })

      const requestHeaders = new Headers({
        'Content-Type': 'application/json'
      })

      const requestParams = {
        method: 'POST',
        body: requestBody,
        headers: requestHeaders
      }

      const codes = {
        1: this.$t('auth.errors.checkEmail'),
        2: this.$t('auth.errors.checkPassword')
      }

      fetch('https://users.backend-pier.com/api/v1/user/login', requestParams)
        .then((res) => res.json())
        .then((data) => {
          this.isLoading = false

          if (data.error_codes) {
            Object.keys(data.error_codes).forEach((key) => {
              if (!data.error_codes[key].length) return

              let newKey = key
              if (key === 'email') {
                newKey = 'login'
              }

              if (!this.errors[newKey]) return

              this.errors[newKey].status = false
              this.errors[newKey].show = true
              this.errors[newKey].message = codes[data.error_codes[key][0]]
            })
          } else {
            this.$store.commit('stateSignIn')
            this.$store.dispatch('signIn', data)
            intercom.authCallback.call(this)
          }
        })
        .catch((err) => console.log(err))
    },
    eye (el) {
      const icon = document.querySelector(`#${el}`)
      const input = icon.parentElement.querySelector('input')
      if (icon.src.includes('close-eye')) {
        icon.src = require('@/assets/images/SignIn/open-eye.svg')
        input.type = 'text'
      } else {
        icon.src = require('@/assets/images/SignIn/close-eye.svg')
        input.type = 'password'
      }
      return false
    },
    validateLogin () {
      let errorMessage = ''
      let emailState = true

      if (this.values.login.length === 0) {
        errorMessage = this.$t('auth.errors.empty')
        emailState = false
      } else {
        errorMessage = ''
        emailState = true
      }

      this.errors.login.status = emailState
      this.errors.login.show = !emailState
      this.errors.login.message = errorMessage
    },
    validatePassword () {
      const passwordState = this.values.password.length !== 0

      this.errors.password.status = passwordState
      this.errors.password.show = !passwordState
      this.errors.password.message = passwordState ? '' : this.$t('auth.errors.empty')
    }
  }
}
</script>
